import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AddFormButton,
  ColumnHeading,
} from "../../../shared/components/TableElements";
import { LinkSpan } from "../../../shared/components/form/FormElements";
import DataReactTable from "../../DataTable/components/DataReactTable";
import { useTranslation } from "react-i18next";
import ExplorePopup from "../../../shared/components/ExplorePopup";
import { initialLoadDelay, NATEXT } from "../../../shared/constants";
import { getListingDisplayDate } from "../../../shared/helpers";
import InventoryRowOptions from "./PartsRowOptions";
import PartsFormPopup from "./PartsFormPopup";
import EditPartPopup from "./EditPartPopup";
import { useSelector } from "react-redux";
import getDefaultFilterData from "../../../shared/constants/getDefaultFilterData";
import Snackbar from "../../../shared/components/Snackbar";
import { updateUserSettingsData } from "../../../features/columns/columnsSlice";
import { post, postApi, putApi } from "../../../api/getUser";
import {
  updateInitialData,
  updateStart,
  updateTotalRecords,
} from "../../../features/filter/filterSlice";
import getUnixTime from "date-fns/getUnixTime";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../contexts/AuthContext";
import SubscribePopup from "../../../shared/components/SubscribePopup";

const PartsTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { isLoggedIn, reload, setReload, setLoading, tag } = useAuth();
  const { currency } = useSelector((state) => state.units);
  const { minDate, maxDate, noOfRecords } = useSelector(
    (state) => state.columns
  );
  const { orgDate } = useSelector((state) => state.user);
  const columns = useSelector((state) => state.advancedFilter.parts);

  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const { data, status, totalRecords } = useSelector((state) => state.filter);
  const {
    draw,
    length,
    search,
    order,
    start_date,
    end_date,
    start,
    filter_group_id,
    filter_vehicle_id,
    querystring,
  } = data;

  const storedFilters = useSelector((state) => state.storedFilters);

  const [rowData, setRowData] = useState([]);

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [subscribePopupErrMsg, setsubscribePopupErrMsg] = useState("");

  const onEssentialUserAddBatch = () => {
    setsubscribePopupErrMsg(t("subscribe_to_advanced_msg"));
    setShowSubscribePopup(true);
  };

  // const rowData = [
  //   {
  //     part_name: "Castrol Radicool",
  //     warehouse_loc: "",
  //     qty: 72,
  //     vendor: "Given Hardware:::Castrol Outlet",
  //     isParent: true,
  //     cost: 100,
  //     notes: "To be used with an oil and filter change",
  //     subRows: [
  //       {
  //         part_name: "Castrol Radicool",
  //         warehouse_loc: "Waterworks",
  //         qty: 36,
  //         isParent: false,
  //         vendor: "Given Hardware",
  //         cost: 50,
  //         notes: "To be used with an oil and filter change",
  //       },
  //       {
  //         part_name: "Castrol Radicool",
  //         warehouse_loc: "Midland Park",
  //         qty: 36,
  //         isParent: false,
  //         vendor: "Castrol Outlet",
  //         cost: 50,
  //         notes: "To be used with an oil and filter change",
  //       },
  //     ],
  //   },
  //   {
  //     part_name: "Shell Engine Oil",
  //     warehouse_loc: "Waterworks",
  //     qty: 150,
  //     isParent: true,
  //     vendor: "Shell Outlet",
  //     cost: 250,
  //     notes: "To be used with an oil and filter change",
  //     // subRows: [
  //     //   { part_name: "", warehouse_loc: "Waterworks", qty: 36 },
  //     //   { part_name: "", warehouse_loc: "Midland Park", qty: 36 },
  //     // ],
  //   },
  // ];

  //let rowData = [];

  const generateRowData = (resData, batchData) => {
    let temp = [];
    if (resData?.length > 0) {
      resData.forEach((row) => {
        let subRowsData = [];
        let locationWiseData = [];
        let locationsObj = {};
        subRowsData = batchData.filter((b) => b.part_id === row.part_id);
        if (subRowsData.length > 0) {
          subRowsData = subRowsData.map((r) => ({
            ...r,
            //part_id: row.part_id,
            //part_name: row.part_name,
            isParent: false,
          }));
          subRowsData.forEach((subRow) => {
            if (locationsObj.hasOwnProperty(subRow.location)) {
              locationsObj[subRow.location].push(subRow);
            } else {
              locationsObj[subRow.location] = [{ ...subRow }];
            }
          });
          for (let key in locationsObj) {
            const locArr = locationsObj[key];
            const vendorsArr = locArr.map((l) => l.vendor).filter((v) => v);
            let vendorsStr = vendorsArr[0] || NATEXT;
            if (vendorsArr?.length > 1) {
              vendorsStr = `${vendorsArr[0]}, +${vendorsArr.length - 1}`;
            }
            if (locArr.length > 1) {
              let totalCost = 0;
              let totalQuantity = 0;

              for (let obj of locArr) {
                const { available_quantity, purchase_cost_per_unit } = obj;
                totalCost += +available_quantity * +purchase_cost_per_unit;
                totalQuantity += available_quantity;
              }
              const costPerUnit =
                totalCost && totalQuantity
                  ? (+totalCost / +totalQuantity).toFixed(2)
                  : 0;
              locationWiseData.push({
                ...locArr[0],
                qty: totalQuantity,
                cost_per_unit: costPerUnit,
                vendor: vendorsStr,
                warehouse_loc: locArr[0].location,
                part_name: row.part_name,
                part_number: row.part_number,
                part_notes: row.part_notes,
                part_img: row.part_img,
              });
            } else {
              locationWiseData.push({
                ...locArr[0],
                cost_per_unit: locArr[0]?.purchase_cost_per_unit?.toFixed(2),
                vendor: vendorsStr,
                warehouse_loc: locArr[0].location,
                qty: locArr[0].available_quantity,
                part_name: row.part_name,
                part_number: row.part_number,
                part_notes: row.part_notes,
                part_img: row.part_img,
              });
            }
          }
        }
        const { qty, purchase_total_cost } = row;
        const costPerUnit =
          purchase_total_cost && qty
            ? (+purchase_total_cost / +qty).toFixed(2)
            : 0;
        temp.push({
          ...row,
          warehouse_loc:
            locationWiseData.length > 1
              ? row?.warehouse_loc
              : locationWiseData[0]?.location,
          isParent: locationWiseData.length > 1,
          cost_per_unit: costPerUnit,
          subRows: locationWiseData.length > 1 ? locationWiseData : [],
        });
      });
    }
    return temp;
  };

  //On initial load, send update initial data
  useEffect(() => {
    if (firstTimeLoad && isLoggedIn && orgDate) {
      (async () => {
        setLoading(true);

        let colOrder = "[0,1,2,3,4,5,6]";
        let minDate = orgDate;
        let defaultRows = 10;

        const apiData = {
          querystring: "get_user_screen_settings",
          screen_name: "parts",
        };

        const userSettingsRes = await putApi(apiData, "dashboard");
        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          if (data) {
            const { min_date, default_rows } = data;
            if (min_date) {
              minDate = min_date;
            }
            defaultRows = default_rows;
          }
          dispatch(
            updateUserSettingsData({
              col_order: colOrder,
              min_date: minDate,
              default_rows: defaultRows,
            })
          );
        }

        let startTime = 0;
        let endTime = getUnixTime(new Date());
        const currentScreenFilters = storedFilters.parts;
        const dateFilter = currentScreenFilters?.find(
          (col) => col?.column === "date"
        );

        if (dateFilter) {
          const { from, to } = dateFilter;
          startTime = from;
          endTime = to;
        }

        const searchFilter = currentScreenFilters?.find(
          (col) => col?.filterName === "search"
        );

        dispatch(
          updateInitialData({
            query: "get_all_inventory_data",
            startDate: startTime,
            endDate: endTime,
            order: 0,
            length: defaultRows ? defaultRows : 10,
            search: searchFilter ? searchFilter.value : "",
            orderType: "asc",
          })
        );

        const initialData = {
          draw: 1,
          length: defaultRows ? defaultRows : 10,
          search: {
            value: searchFilter ? searchFilter?.value : "",
            regex: false,
          },
          order: [
            {
              column: 0,
              dir: "asc",
            },
          ],
          start_date: startTime,
          end_date: endTime,
          start: 0,
          // filter_group_id: "",
          // filter_vehicle_id: "",
          //filter_group_id,
          //filter_vehicle_id,
          querystring: "get_all_inventory_data",
        };

        const partsRes = await post("listing", {
          ...initialData,
          ...columns,
        });
        dispatch(updateTotalRecords({ recordsTotal: partsRes?.recordsTotal }));
        if (partsRes?.data) {
          const { data, batch_data } = partsRes;
          let res = generateRowData(data, batch_data);

          setRowData(res);
        }
        setLoading(false);
        setTimeout(() => {
          setFirstTimeLoad(false);
        }, initialLoadDelay);
      })();
    }
  }, [isLoggedIn, orgDate, columns]);

  //Fetch data on every filter change expect for start and set start to 0
  useEffect(() => {
    if (
      !firstTimeLoad &&
      isLoggedIn &&
      querystring === "get_all_inventory_data" &&
      !reload
    ) {
      setLoading(true);
      dispatch(updateStart(0));
      (async () => {
        const partsRes = await post("listing", {
          ...data,
          ...columns,
          start: 0,
        });
        dispatch(updateTotalRecords({ recordsTotal: partsRes?.recordsTotal }));
        if (partsRes?.data) {
          const { data, batch_data } = partsRes;
          let res = generateRowData(data, batch_data);

          setRowData(res);
        }
        setLoading(false);
      })();
    }
  }, [
    draw,
    length,
    //start,
    search,
    order,
    start_date,
    end_date,
    //filter_group_id,
    //filter_vehicle_id,
    //querystring,
    columns,
  ]);

  //Fetch data on change of start
  useEffect(() => {
    if (
      !firstTimeLoad &&
      isLoggedIn &&
      querystring === "get_all_inventory_data" &&
      !reload
    ) {
      (async () => {
        setLoading(true);
        const partsRes = await post("listing", {
          ...data,
          ...columns,
        });
        dispatch(updateTotalRecords({ recordsTotal: partsRes?.recordsTotal }));
        if (partsRes?.data) {
          const { data, batch_data } = partsRes;
          let res = generateRowData(data, batch_data);

          setRowData(res);
        }
        setLoading(false);
      })();
    }
  }, [start]);

  //Reload Effect
  useEffect(() => {
    if (
      !firstTimeLoad &&
      isLoggedIn &&
      querystring === "get_all_inventory_data" &&
      reload === "parts"
    ) {
      dispatch(updateStart(0));
      (async () => {
        setLoading(true);
        const partsRes = await post("listing", {
          ...data,
          ...columns,
          start: 0,
        });
        dispatch(updateTotalRecords({ recordsTotal: partsRes?.recordsTotal }));
        if (partsRes?.data) {
          const { data, batch_data } = partsRes;
          let res = generateRowData(data, batch_data);

          setRowData(res);
        }
        setLoading(false);
      })();

      setReload("");
    }
  }, [reload]);

  const [showExplorePopup, setShowExplorePopup] = useState(false);

  const [showPartsForm, setShowPartsForm] = useState(false);
  const [showEditPart, setShowEditPart] = useState(false);
  //add-batch, edit-part
  const [mode, setMode] = useState("");
  const [singleRowData, setSingleRowData] = useState(null);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("part_name"),
        accessor: "part_name",
        disableFilters: true,
        Cell: ({ row }) =>
          row?.original?.isParent || row?.original?.subRows?.length === 0
            ? row?.original?.part_name
            : NATEXT,
      },
      {
        id: 2,
        Header: t("part_no"),
        accessor: "part_number",
        disableFilters: true,
        Cell: ({ row }) =>
          row?.original?.isParent || row?.original?.subRows?.length === 0
            ? row?.original?.part_number || NATEXT
            : NATEXT,
      },

      {
        id: 3,
        Header: t("warehouse_loc"),
        accessor: "warehouse_loc",
        disableCol: true,
        //disableFilters: true,
        //width: getColumnWidth("fillup_date", "Date"),
        Cell: ({ row }) => {
          const { subRows, warehouse_loc, isParent } = row?.original;
          return subRows?.length > 0
            ? t("no_of_locations_sm", {
                count: subRows?.length,
              })
            : warehouse_loc
            ? warehouse_loc
            : NATEXT;
        },
      },
      {
        id: 4,
        Header: t("qty_tv"),
        accessor: "qty",
        Cell: ({ value }) => value || NATEXT,
        //disableFilters: true,
        //width: getColumnWidth("odo", "Odo"),
        // disableGlobalFilter: false,
      },
      {
        id: 5,
        Header: t("vendor"),
        accessor: "vendor",
        Cell: ({ row }) => {
          const { vendor, isParent } = row?.original;
          let vendorText = vendor || NATEXT;
          // if (isParent) {
          if (vendor && vendor.includes(":::")) {
            let vendorArr = vendor.split(":::").filter((v) => v);
            if (vendorArr?.length > 1) {
              vendorText = `${vendorArr[0]}, +${vendorArr?.length - 1}`;
            }
            if (vendorArr?.length === 1) {
              vendorText = vendorArr[0];
            }
          }
          // }
          return vendorText;
        },
      },
      {
        id: 6,
        Header: t("avg_cost_per_unit"),
        accessor: "cost_per_unit",
        Cell: ({ value }) => (value ? `${value} ${currency}` : NATEXT),
      },
      {
        id: "edit",
        Header: "",
        accessor: "edit",
        accessorId: "edit",
        Cell: ({ row }) => (
          <InventoryRowOptions
            rowData={row?.original}
            setShowPartsForm={setShowPartsForm}
            setMode={setMode}
            setShowEditPart={setShowEditPart}
            setSingleRowData={setSingleRowData}
            onEssentialUserAddBatch={onEssentialUserAddBatch}
          />
        ),
        disableSortBy: true,
      },
    ],
    []
  );

  const { defaultContainsData, defaultRangeData } = getDefaultFilterData();

  const initialData = useMemo(
    () => ({
      part_name: {
        column: "part_name",
        name: t("part_name"),
        ...defaultContainsData,
      },
      part_number: {
        column: "part_number",
        name: t("part_no"),
        ...defaultContainsData,
      },
      barcode: {
        column: "barcode",
        name: t("upc_barcode"),
        ...defaultContainsData,
      },
      location: {
        column: "location",
        name: t("warehouse_loc"),
        ...defaultContainsData,
      },
      date: {
        column: "date",
        name: t("purchase_date"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
        //type: "internal",
      },
      available_quantity: {
        column: "available_quantity",
        name: t("available_qty"),
        ...defaultRangeData,
      },
      vendor: {
        column: "vendor",
        name: t("vendor"),
        ...defaultContainsData,
      },
      batch_id: {
        column: "batch_id",
        name: t("batch_id"),
        ...defaultContainsData,
      },
      store_room: {
        column: "store_room",
        name: t("store_room"),
        ...defaultContainsData,
      },
      custom_fields: {
        column: "custom_fields",
        name: t("custom_fields"),
        hideIsFilter: true,
        hideIsNotFilter: true,
        ...defaultContainsData,
        radioValue: "contains",
        radioName: t("contains"),
      },
    }),
    [minDate]
  );

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  useEffect(() => {
    if (snackbarMsg) {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
        setSnackbarMsg("");
      }, 5000);
    }
  }, [snackbarMsg]);

  const handleAddPart = () => {
    if (tag === "free-user" && totalRecords >= 3) {
      setsubscribePopupErrMsg(t("no_of_parts_limit_err"));
      setShowSubscribePopup(true);
      return;
    }

    if (tag === "essential-user") {
      setsubscribePopupErrMsg(t("subscribe_to_advanced_msg"));
      setShowSubscribePopup(true);
      return;
    }
    setSingleRowData(null);
    setMode("add-part");
    setShowPartsForm(true);
  };

  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={subscribePopupErrMsg}
        />
      )}
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://www.youtube.com/embed/mAJtZECTH9c"}
        />
      )}
      {showPartsForm && (
        <PartsFormPopup
          showPopup={showPartsForm}
          setShowPopup={setShowPartsForm}
          mode={mode}
          rowData={singleRowData}
          setSnackbarMsg={setSnackbarMsg}
          //setShowSnackbar={setShowSnackbar}
        />
      )}
      {showEditPart && (
        <EditPartPopup
          showPopup={showEditPart}
          setShowPopup={setShowEditPart}
          rowData={singleRowData}
          setSnackbarMsg={setSnackbarMsg}
        />
      )}
      {showSnackbar && (
        <Snackbar
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
          message={snackbarMsg}
          //snackbarType={snackbarData.type}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around p-0 m-0">
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("parts")}</ColumnHeading>
            <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan>
          </Col>
          <Col className="d-flex justify-content-end mb-1">
            {/* <Link to={"/fillups/addnew"}> */}
            <AddFormButton id="add-part-button" onClick={handleAddPart}>
              {t("add_part")}
            </AddFormButton>
            {/* </Link> */}
          </Col>
        </Row>
        <DataReactTable
          reactTableData={reactTableData}
          //valuesArray={fillupsValuesArray}
          initialData={initialData}
          setSnackbarMsg={setSnackbarMsg} //For Parts Module
          //defaultColumns={defaultColumns}
        />
      </Container>
    </>
    // <div style={{ height: '90%', background: 'lightblue' }}>Hi</div>
  );
};

export default PartsTable;
