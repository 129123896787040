import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export const defaultFillupsColumns = {
  0: { accessor: "Vehicle", header: "Vehicle" },
  1: { accessor: "Date", header: "Date" },
  2: { accessor: "Odo", header: "Odo" },
  3: { accessor: "Distance", header: "Distance" },
  4: { accessor: "Quantity", header: "Quantity" },
  5: { accessor: "Total Cost", header: "Total Cost" },
  6: { accessor: "Efficiency", header: "Efficiency" },
  7: { accessor: "Filled By", header: "Filled By" },
  8: { accessor: "Filling Station", header: "Filling Station" },
  9: { accessor: "Transaction Type", header: "Transaction Type" },
  10: { accessor: "Card Number", header: "Card Number" },
  11: { accessor: "Fuel Brand", header: "Fuel Brand" },
  12: { accessor: "Fuel Type", header: "Fuel Type" },
  13: { accessor: "Comments", header: "Comments" },
  //14: "Group",
};

export const defaultServicesColumns = {
  0: { accessor: "name", header: "Vehicle" },
  1: { accessor: "service_date", header: "Date" },
  2: { accessor: "odo", header: "Odo" },
  3: { accessor: "service_station", header: "Service Station" },
  4: { accessor: "service_task_name", header: "Service Task" },
  5: { accessor: "total", header: "Total Cost" },
  6: { accessor: "labor_total", header: "Labor Cost" },
  7: { accessor: "parts_total", header: "Parts Cost" },
  8: { accessor: "transaction_type", header: "Transaction Type" },
  9: { accessor: "card_number", header: "Card Number" },
  10: { accessor: "uname", header: "Serviced By" },
  11: { accessor: "comments", header: "Comments" },
  12: { accessor: "total_time", header: "Total Duration" },
  //14: "Group",
};

export const defaultExpensesColumns = {
  0: { accessor: "name", header: "Vehicle" },
  1: { accessor: "expense_date", header: "Date" },
  2: { accessor: "odo", header: "Odo" },
  3: { accessor: "vendor", header: "Vendor" },
  4: { accessor: "expense_task_name", header: "Expense Task" },
  5: { accessor: "total_cost", header: "Total Cost" },
  6: { accessor: "transaction_type", header: "Transaction Type" },
  7: { accessor: "card_number", header: "Card Number" },
  8: { accessor: "uname", header: "Service By" },
  9: { accessor: "comments", header: "Comments" },
  //10: { accessor: "Group", header: "Group" },
};

export const defaultTripsColumns = {
  0: { accessor: "name", header: "Vehicle" },
  1: { accessor: "dep_date", header: "Dep Date" },
  2: { accessor: "dep_odo", header: "Dep Odo" },
  3: { accessor: "dep_loc", header: "Departure" },
  4: { accessor: "arr_date", header: "Arr Date" },
  5: { accessor: "arr_odo", header: "Arr Odo" },
  6: { accessor: "arr_loc", header: "Arrival" },
  7: { accessor: "trip_distance", header: "Distance" },
  8: { accessor: "trip_time", header: "Time" },
  9: { accessor: "uname", header: "Trip By" },
  10: { accessor: "comments", header: "Comments" },
  //10: { accessor: "Group", header: "Group" },
};

export const defaultMaintenanceRemindersColumns = {
  0: { accessor: "NAME", header: "Vehicle" },
  1: { accessor: "service_task_name", header: "Service Task" },
  2: { accessor: "last_odo_of_service", header: "Last Odo of Service" },
  3: { accessor: "last_date_of_service", header: "Last Date of Service" },
  4: { accessor: "DUE IN", header: "DUE IN" },
  5: { accessor: "DUE ODO", header: "DUE ODO" },
  //1: { accessor: "Due Period", header: "Due Period" },
  //2: { accessor: "Due Odo / Date", header: "Due Odo / Date" },
  //3: { accessor: "Last Service", header: "Last Service" },
  //4: { accessor: "PERCENTAGE", header: "PERCENTAGE" },
};

export const defaultRenewalsColumns = {
  0: { accessor: "NAME", header: "Vehicle" },
  1: { accessor: "expense_task_name", header: "Expense Task" },
  2: { accessor: "last_odo_of_service", header: "Last Odo of Expense" },
  3: { accessor: "last_date_of_service", header: "Last Date of Expense" },
  4: { accessor: "DUE IN", header: "DUE IN" },
  5: { accessor: "DUE ODO", header: "DUE ODO" },
  // 0: { accessor: "Vehicle", header: "Vehicle" },
  // 1: { accessor: "Due Period", header: "Due Period" },
  // 2: { accessor: "Due Odo / Date", header: "Due Odo / Date" },
  // 3: { accessor: "Last Renewal", header: "Last Renewal" },
  // 4: { accessor: "PERCENTAGE", header: "PERCENTAGE" },
};

export const defaultDailyMileageColumns = {
  0: { accessor: "Vehicle Name", header: "Vehicle Name" },
  1: { accessor: "User Name", header: "Entered By" },
  2: { accessor: "Dates", header: "Date" },
  3: { accessor: "Starting Odometer", header: "Starting Odometer" },
  4: { accessor: "Ending Odometer", header: "Ending Odometer" },
  5: { accessor: "Distance", header: "Distance" },
  6: { accessor: "comments", header: "Comments" },
  7: { accessor: "group_name", header: "Group" },
};

export const defaultInspectionSubmissionsColumns = {
  0: { accessor: "name", header: "Vehicle" },
  1: { accessor: "form_name", header: "Form Name" },
  2: { accessor: "Inspection Date", header: "Inspection Date" },
  3: { accessor: "user_name", header: "Inspection by" },
  4: { accessor: "task_name", header: "Issues" },
};

export const defaultIssuesColumns = {
  0: { accessor: "Vehicle", header: "Vehicle" },
  1: { accessor: "issue", header: "Issue" },
  2: { accessor: "issue_desc", header: "Issue Description" },
  3: { accessor: "status", header: "Status" },
  4: { accessor: "priority", header: "Priority" },
  5: { accessor: "reported_date", header: "Reported Date" },
  6: { accessor: "reported_by", header: "Reported By" },
  7: { accessor: "vehicle_status", header: "Vehicle Status" },
  8: { accessor: "odo", header: "Odometer" },
  9: { accessor: "close_date", header: "Time to Close" },
  10: { accessor: "source", header: "Source" },
  11: { accessor: "comments", header: "Comments" },
};

export const defaultUserManagementColumns = {
  0: { accessor: "name", header: "Name" },
  1: { accessor: "email", header: "Email" },
  2: { accessor: "phone", header: "Phone" },
  3: { accessor: "role_name", header: "Role" },
  4: { accessor: "manager_name", header: "Manager" },
  5: { accessor: "joining_date", header: "Joining Date" },
  6: { accessor: "user_active", header: "Status" },
};

export const defaultVehiclesColumns = {
  0: { accessor: "name", header: "Vehicle" },
  1: { accessor: "year", header: "Year" },
  2: { accessor: "veh_type", header: "Type" },
  3: { accessor: "veh_active", header: "Status" },
  4: { accessor: "operational_status", header: "Operational Status" },
  5: { accessor: "make", header: "Make" },
  6: { accessor: "model", header: "Model" },
  7: { accessor: "license_no", header: "License" },
  8: { accessor: "vin_no", header: "VIN" },
  9: { accessor: "primary_meter", header: "Primary Meter" },
  10: { accessor: "fuel_type", header: "Fuel Type" },
  11: { accessor: "odo", header: "Current Odometer" },
  12: { accessor: "group_id", header: "Group" },
};

export const defaultDailyTSColumns = {
  0: { accessor: "name", header: "Name" },
  1: { accessor: "veh_name", header: "Vehicle Name" },
  2: { accessor: "timesheet_date", header: "Date" },
};

export const defaultWorkOrderColumns = {
  0: { accessor: "name", header: "Vehicle" },
  1: { accessor: "type", header: "Type" },
  2: { accessor: "all_issues", header: "Issues" },
  3: { accessor: "create_date", header: "Created Date" },
  4: { accessor: "assigned_to", header: "Assigned To" },
  5: { accessor: "status", header: "Status" },
  6: { accessor: "duration", header: "Duration" },
  7: { accessor: "odo", header: "Odometer" },
  8: { accessor: "wo_number", header: "WO Number" },
  9: { accessor: "priority", header: "Priority" },
  10: { accessor: "created_by", header: "Created By" },
  11: { accessor: "due_date", header: "Due On" },
  12: { accessor: "po_number", header: "PO Number" },
  13: { accessor: "invoice_number", header: "Invoice Number" },
  14: { accessor: "total_cost", header: "Total Cost" },
  15: { accessor: "comments", header: "Comments" },
};

export const getCsvPdfData = () => {
  const location = useLocation();
  const { t } = useTranslation("common");
  const { pathname } = location;

  let pdfHeader = "";
  let pdfFileName = "";
  let defaultColumns = null;
  if (pathname.includes("trips")) {
    pdfHeader = t("trips");
    pdfFileName = "trips";
    defaultColumns = {
      0: { accessor: "name", header: t("vehicle") },
      1: { accessor: "dep_date", header: t("dep_date_and_time") },
      2: { accessor: "dep_loc", header: t("dep_loc") },
      3: { accessor: "dep_odo", header: t("dep_odo") },
      4: { accessor: "arr_date", header: t("arr_date_and_time") },
      5: { accessor: "arr_loc", header: t("arr_loc") },
      6: { accessor: "arr_odo", header: t("arr_odo") },
      7: { accessor: "trip_distance", header: t("trp_distance") },
      8: { accessor: "trip_time", header: t("trip_time") },
      9: { accessor: "uname", header: t("trip_by") },
      10: { accessor: "total_fillup_cost", header: t("fillup_cost") },
      11: { accessor: "total_expense_cost", header: t("expense_cost") },
      12: { accessor: "total_income_cost", header: t("income_cost") },
      13: { accessor: "comments", header: t("notes_tv") },
      //10: { accessor: "Group", header: "Group" },
    };
  }

  if (pathname.includes("vehicles")) {
    pdfHeader = t("vehicles");
    pdfFileName = "vehicles"; //t("vehicle");
    defaultColumns = {
      0: { accessor: "name", header: t("vehicle") },
      1: { accessor: "year", header: t("year") },
      2: { accessor: "veh_type", header: t("type") },
      3: { accessor: "veh_active", header: t("status") },
      4: { accessor: "operational_status", header: t("operational_status") },
      5: { accessor: "make", header: t("make") },
      6: { accessor: "model", header: t("model") },
      7: { accessor: "license_no", header: t("license") },
      8: { accessor: "vin_no", header: t("vin") },
      9: { accessor: "primary_meter", header: t("primary_meter") },
      10: { accessor: "fuel_type", header: t("fuel_type") },
      11: { accessor: "odo", header: t("current_odo") },
      12: { accessor: "group_id", header: t("group") },
    };
  }

  if (pathname.includes("fillups")) {
    pdfHeader = t("fillups");
    pdfFileName = "fillups"; //t("fillups");
    defaultColumns = {
      0: { accessor: "Vehicle", header: t("vehicle") },
      1: { accessor: "Date", header: t("date_tv") },
      2: { accessor: "Odo", header: t("odo") },
      3: { accessor: "Distance", header: t("distance") },
      4: { accessor: "Quantity", header: t("qty_tv") },
      5: { accessor: "Total Cost", header: t("tc_tv") },
      6: { accessor: "Efficiency", header: t("efficiency") },
      7: { accessor: "Filled By", header: t("filled_by") },
      8: { accessor: "Filling Station", header: t("fs_tv") },
      9: { accessor: "Transaction Type", header: t("transaction_type") },
      10: { accessor: "Card Number", header: t("card_number") },
      11: { accessor: "Fuel Brand", header: t("fb_tv") },
      12: { accessor: "Fuel Type", header: t("fuel_type") },
      13: { accessor: "Comments", header: t("notes_tv") },
      //14: "Group",
    };
  }

  if (pathname.includes("services")) {
    pdfHeader = t("services");
    pdfFileName = "services"; //t("services");
    defaultColumns = {
      0: { accessor: "name", header: t("vehicle") },
      1: { accessor: "service_date", header: t("date_tv") },
      2: { accessor: "odo", header: t("odo") },
      3: { accessor: "service_station", header: t("service_station") },
      4: { accessor: "service_task_name", header: t("service_task") },
      5: { accessor: "total", header: t("tc_tv") },
      6: { accessor: "labor_total", header: t("labor_cost") },
      7: { accessor: "parts_total", header: t("parts_cost") },
      8: { accessor: "transaction_type", header: t("transaction_type") },
      9: { accessor: "card_number", header: t("card_number") },
      10: { accessor: "uname", header: t("serviced_by") },
      11: { accessor: "comments", header: t("notes_tv") },
      12: { accessor: "total_time", header: t("total_duration") },
      13: { accessor: "part_names", header: t("parts_consumed") },
      //14: "Group",
    };
  }

  if (pathname.includes("expenses")) {
    pdfHeader = t("expenses_title");
    pdfFileName = "expenses"; //t("expenses_title");
    defaultColumns = {
      0: { accessor: "name", header: t("vehicle") },
      1: { accessor: "expense_date", header: t("date_tv") },
      2: { accessor: "odo", header: t("odo") },
      3: { accessor: "vendor", header: t("vendor") },
      4: { accessor: "expense_task_name", header: t("expense_task_name") },
      5: { accessor: "total_cost", header: t("tc_tv") },
      6: { accessor: "transaction_type", header: t("transaction_type") },
      7: { accessor: "card_number", header: t("card_number") },
      8: { accessor: "uname", header: t("expense_by") },
      9: { accessor: "comments", header: t("notes_tv") },
      //10: { accessor: "Group", header: "Group" },
    };
  }

  if (pathname.includes("maintenancereminders")) {
    pdfHeader = t("maintenance_reminders");
    pdfFileName = "maintenance_reminders"; //t("maintenance_reminders");
    defaultColumns = {
      0: { accessor: "NAME", header: t("vehicle") },
      1: { accessor: "service_task_name", header: t("service_task") },
      2: { accessor: "last_odo_of_service", header: t("last_service_odo") },
      3: { accessor: "last_date_of_service", header: t("last_service_date") },
      4: { accessor: "DUE IN", header: t("due_in") },
      5: { accessor: "DUE ODO", header: t("due_on") },
    };
  }

  if (pathname.includes("renewals")) {
    pdfHeader = t("renewals");
    pdfFileName = "renewals"; //t("renewals");
    defaultColumns = {
      0: { accessor: "NAME", header: t("vehicle") },
      1: { accessor: "expense_task_name", header: t("expense_task") },
      2: { accessor: "last_odo_of_service", header: t("last_expense_odo") },
      3: { accessor: "last_date_of_service", header: t("last_expense_date") },
      4: { accessor: "DUE IN", header: t("due_in") },
      5: { accessor: "DUE ODO", header: t("due_on") },
    };
  }

  if (pathname.includes("dailymileage")) {
    pdfHeader = t("se_mileage");
    pdfFileName = "daily_mileage"; //t("se_mileage");
    defaultColumns = {
      0: { accessor: "Vehicle Name", header: t("vehicle") },
      1: { accessor: "User Name", header: t("entered_by") },
      2: { accessor: "Dates", header: t("date_tv") },
      3: { accessor: "Starting Odometer", header: t("starting_odo") },
      4: { accessor: "Ending Odometer", header: t("ending_odo") },
      5: { accessor: "Distance", header: t("distance") },
      6: { accessor: "comments", header: t("notes_tv") },
      7: { accessor: "group_name", header: t("group") },
    };
  }

  if (pathname.includes("inspectionsubmissions")) {
    pdfHeader = t("inspection_submissions");
    pdfFileName = "inspections"; //t("inspection_submissions");
    defaultColumns = {
      0: { accessor: "name", header: t("vehicle") },
      1: { accessor: "form_name", header: t("inspection_form_name") },
      2: { accessor: "Inspection Date", header: t("inspection_date") },
      3: { accessor: "user_name", header: t("inspection_by") },
      4: { accessor: "task_name", header: t("issues") },
    };
  }

  if (pathname.includes("issues")) {
    pdfHeader = t("issues");
    pdfFileName = "issues"; //t("issues");
    defaultColumns = {
      0: { accessor: "Vehicle", header: t("vehicle") },
      1: { accessor: "issue", header: t("issue") },
      2: { accessor: "issue_desc", header: t("issue_desc") },
      3: { accessor: "status", header: t("status") },
      4: { accessor: "priority", header: t("priority") },
      5: { accessor: "reported_date", header: t("reported_date") },
      6: { accessor: "reported_by", header: t("reported_by") },
      7: { accessor: "vehicle_status", header: t("vehicle_status") },
      8: { accessor: "odo", header: t("odo") },
      9: { accessor: "close_date", header: t("time_to_close") },
      10: { accessor: "source", header: t("source") },
      11: { accessor: "comments", header: t("notes_tv") },
    };
  }

  if (pathname.includes("users")) {
    pdfHeader = t("user_management");
    pdfFileName = "users"; //t("user_management");
    defaultColumns = {
      0: { accessor: "name", header: t("name") },
      1: { accessor: "email", header: t("email") },
      2: { accessor: "phone", header: t("phone") },
      3: { accessor: "role_name", header: t("role") },
      4: { accessor: "manager_name", header: t("manager") },
      5: { accessor: "joining_date", header: t("joining_date") },
      6: { accessor: "user_active", header: t("status") },
    };
  }

  if (pathname.includes("dailytimesheet")) {
    pdfHeader = t("daily_ts");
    pdfFileName = "daily_time_sheet"; //t("daily_ts");
    defaultColumns = {
      0: { accessor: "name", header: t("name") },
      1: { accessor: "veh_name", header: t("veh_name") },
      2: { accessor: "timesheet_date", header: t("date_tv") },
    };
  }

  if (pathname.includes("workorders")) {
    pdfHeader = t("work_orders");
    pdfFileName = "work_orders"; //t("work_orders");
    defaultColumns = {
      0: { accessor: "name", header: t("vehicle") },
      1: { accessor: "type", header: t("type") },
      2: { accessor: "all_issues", header: t("issues") },
      3: { accessor: "create_date", header: t("created_date") },
      4: { accessor: "assigned_to", header: t("assigned_to") },
      5: { accessor: "status", header: t("status") },
      6: { accessor: "duration", header: t("duration") },
      7: { accessor: "odo", header: t("odometer") },
      8: { accessor: "wo_number", header: t("wo_number") },
      9: { accessor: "priority", header: t("work_order_priority") },
      10: { accessor: "created_by", header: t("created_by") },
      11: { accessor: "due_date", header: t("due_on") },
      12: { accessor: "po_number", header: t("po_number") },
      13: { accessor: "invoice_number", header: t("invoice_number") },
      14: { accessor: "total_cost", header: t("tc_tv") },
      15: { accessor: "comments", header: t("notes_tv") },
    };
  }

  if (pathname.includes("inspectionscheduling")) {
    pdfHeader = t("inspection_schedules");
    pdfFileName = "inspection_schedule"; //t("maintenance_reminders");
    defaultColumns = {
      0: { accessor: "name", header: t("vehicle") },
      1: { accessor: "form_name", header: t("inspection_form_name") },
      2: { accessor: "status", header: t("status") },
      3: { accessor: "due_in", header: t("due_in") },
      4: { accessor: "due_on", header: t("due_on") },
      5: { accessor: "last_inspected", header: t("last_inspected") },
    };
  }

  if (pathname.includes("parts")) {
    pdfHeader = t("parts");
    pdfFileName = "parts"; //t("maintenance_reminders");
    defaultColumns = {
      0: { accessor: "part_name", header: t("part_name") },
      1: { accessor: "part_number", header: t("part_no") },
      2: { accessor: "location", header: t("warehouse_loc") },
      3: { accessor: "qty", header: t("qty_tv") },
      4: { accessor: "vendor", header: t("vendors") },
      5: { accessor: "cost_per_unit", header: t("avg_cost_per_unit") },
    };
  }

  return { pdfHeader, pdfFileName, defaultColumns };
};
