import React from "react";
import { OptionsMenu } from "./MoreOptions/MoreOptions";
import { useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { RowOptionsButtonContainer } from "../../TableElements";
import { DeletePopup } from "./DeletePopup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ZoomModal from "../../ZoomModal";
import useClickAway from "../../../custom-hooks/useClickAway";
import InfoPopup from "../../InfoPopup";
import { s3Url } from "../../../constants";

const RowOptions = ({ rowData }) => {
  const { orgId, userId } = useSelector((state) => state.user);
  const [toggleOptions, setToggleOptions] = useState(false);

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [deleteApiData, setDeleteApiData] = useState({});

  const clickAwayRef = useClickAway({
    handleCloseElement: () => setToggleOptions(false),
  });

  //State to show toggle zoomed doc popup for View Document in daily ts
  const [showZoomedDoc, setShowZoomedDoc] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  //These two states are for Info Popup which is shown in case record cannot be deleted
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [infoData, setInfoData] = useState({});

  const handleClick = (e) => {
    e.preventDefault();
    setToggleOptions(!toggleOptions);
  };

  return (
    <div
      className="d-flex align-items-start justify-content-center position-relative"
      ref={clickAwayRef}
    >
      <RowOptionsButtonContainer
        id="row-options-button"
        style={{ marginTop: 0 }}
        variant="outline-secondary"
        size="sm"
        onClick={handleClick}
      >
        <i className="fi fi-rr-menu-dots-vertical"></i>
      </RowOptionsButtonContainer>
      {/* {toggleOptions && (
        <TopbarBack
          type="button"
          aria-label="profile button"
          onClick={() => setToggleOptions(!toggleOptions)}
        />
      )} */}
      {toggleOptions && (
        <RowOptionsMenu
          rowData={rowData}
          toggleOptions={toggleOptions}
          setToggleOptions={setToggleOptions}
          showZoomedDoc={showZoomedDoc}
          setShowZoomedDoc={setShowZoomedDoc}
          showDeletePopup={showDeletePopup}
          setShowDeletePopup={setShowDeletePopup}
          deleteApiData={deleteApiData}
          setDeleteApiData={setDeleteApiData}
          path={path}
        />
      )}
      {/* {showZoomedDoc && (
        <ZoomedDocPopup
          showPopup={showZoomedDoc}
          setShowPopup={setShowZoomedDoc}
          img={{
            url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/user_${userId}/daily_sheet/${rowData?.timesheet_img}`,
          }}
        />
      )} */}
      {showZoomedDoc && (
        <ZoomModal
          showModal={showZoomedDoc}
          setShowModal={setShowZoomedDoc}
          doc={{
            url: `${s3Url}/org_${orgId}/user_${rowData?.user_id}/daily_sheet/${rowData?.timesheet_img}`,
          }}
        />
      )}
      {showDeletePopup && (
        <DeletePopup
          showPopup={showDeletePopup}
          setShowPopup={setShowDeletePopup}
          deleteApiData={deleteApiData}
          screen={path}
          setShowInfoPopup={setShowInfoPopup}
          setInfoData={setInfoData}
        />
      )}
      {showInfoPopup && (
        <InfoPopup
          showPopup={showInfoPopup}
          setShowPopup={setShowInfoPopup}
          message={infoData.msg}
          icon={infoData.icon}
          listMsg={infoData.listMsg}
        />
      )}
    </div>
    // <div className="d-flex align-items-start justify-content-end">
    //   <DropdownContainer>
    //     <Dropdown.Toggle id="dropdown-basic">
    //       {/* <MoreVertIcon /> */}
    //       <MoreOptionsIcon />
    //     </Dropdown.Toggle>

    //     <Dropdown.Menu>
    //       <Dropdown.Item>View</Dropdown.Item>
    //       <Dropdown.Item>Edit</Dropdown.Item>
    //       <Dropdown.Item>Delete</Dropdown.Item>
    //     </Dropdown.Menu>
    //   </DropdownContainer>
    // </div>
  );
};

export default RowOptions;

const RowOptionsMenu = ({
  rowData,
  toggleOptions,
  setToggleOptions,
  showDeletePopup,
  setShowDeletePopup,
  deleteApiData,
  setDeleteApiData,
  showZoomedDoc,
  setShowZoomedDoc,
  path,
}) => {
  const history = useHistory();
  const { t } = useTranslation("common");
  //const location = useLocation();
  //const path = location.pathname;
  //let showCreateNew = false;
  const [showEdit, setShowEdit] = useState(true);
  const [showCreateNew, setShowCreateNew] = useState(false);

  //console.log("rowData", rowData);

  const vehiclesList = useSelector((state) => state.vehicles.list);

  useEffect(() => {
    setDeleteApiData(getDeleteApiData());
    //console.log(deleteApiData);
  }, []);

  useEffect(() => {
    const currentVehicle = vehiclesList?.find(
      (v) => v.vehicleId === rowData?.veh_id
    );

    if (!path.includes("vehicles") && currentVehicle) {
      if (
        (currentVehicle.veh_active === 0 &&
          currentVehicle.secondary_status === 0) ||
        (currentVehicle.veh_active === 2 &&
          currentVehicle.secondary_status === 0)
      ) {
        //showEdit = false;
        setShowEdit(false);
      } else {
        //showEdit = true;
        setShowEdit(true);
      }
    }

    if (path.includes("maintenancereminders") || path.includes("renewals")) {
      if (
        (currentVehicle.veh_active === 0 &&
          currentVehicle.secondary_status === 0) ||
        (currentVehicle.veh_active === 2 &&
          currentVehicle.secondary_status === 0)
      ) {
        //showCreateNew = false;
        setShowCreateNew(false);
      } else {
        //showCreateNew = true;
        setShowCreateNew(true);
      }
    }
  }, [vehiclesList, rowData]);

  //Effects to close rowoptions menu when view/delete is clicked
  // useEffect(() => {
  //   if (showDeletePopup) {
  //     setToggleOptions(false);
  //   }
  // }, [showDeletePopup]);

  // useEffect(() => {
  //   if (showZoomedDoc) {
  //     setToggleOptions(false);
  //   }
  // }, [showZoomedDoc]);

  const getDeleteApiData = () => {
    if (path.includes("inspectiontasks")) {
      // return {
      //   action: 3,
      //   description:
      //     rowData.description && rowData.description !== NATEXT
      //       ? rowData.description
      //       : "",
      //   image_required: rowData.image_required,
      //   inspection_task_id: rowData.task_id,
      //   inspection_task_name: rowData.task_name,
      //   operation: "update",
      //   pass_required: rowData.pass_required,
      //   querystring: "addEditInspectionTask",
      //   value_required: rowData.value_required,
      // };
      return {
        delete_ids: rowData.task_id,
        querystring: "multiple_delete",
        sub_query_string: "INSPECTION_TASKS",
      };
    } else if (path.includes("inspectionforms")) {
      // return {
      //   form_id: rowData.form_id,
      //   querystring: "deleteinspectionform",
      // };
      return {
        delete_ids: rowData.form_id,
        querystring: "multiple_delete",
        sub_query_string: "INSPECTION_FORMS",
      };
    } else if (path.includes("inspectionscheduling")) {
      // return {
      //   form_id: rowData.form_id,
      //   querystring: "deleteinspectionform",
      // };
      return {
        delete_ids: rowData.inspection_schedule_id,
        querystring: "multiple_delete",
        sub_query_string: "INSPECTION_SCHEDULE",
      };
    } else if (path.includes("servicetasks")) {
      // return {
      //   action: 3,
      //   service_task_id: rowData.service_task_id,
      //   querystring: "update_servicetask",
      //   recurring: rowData?.recurring,
      //   servicetask_veh_id: rowData.veh_id,
      //   service_task_name: rowData?.service_task_name,
      // };
      return {
        delete_ids: rowData.service_task_id,
        querystring: "multiple_delete",
        sub_query_string: "SERVICE_TASKS",
      };
    } else if (path.includes("expensetasks")) {
      // return {
      //   action: 3,
      //   expense_task_id: rowData.expense_task_id,
      //   expense_task_name: rowData?.expense_task_name,
      //   querystring: "update_expensetask",
      //   recurring: rowData?.recurring,
      //   expensetask_veh_id: rowData.veh_id,
      //   comments: rowData.comments,
      // };
      return {
        delete_ids: rowData.expense_task_id,
        querystring: "multiple_delete",
        sub_query_string: "EXPENSE_TASKS",
      };
    } else if (path.includes("dailytimesheet")) {
      return {
        delete_ids: rowData.timesheet_img,
        querystring: "multiple_delete",
        sub_query_string: "DAILY_TIMESHEET",
      };
      // return {
      //   querystring: "deletedailysheet",
      //   operator_id: rowData?.user_id,
      //   timesheet_date: rowData?.timesheet_date,
      // };
    } else if (path.includes("maintenancereminders")) {
      return {
        //screenName: screenName,
        delete_ids: rowData.reminder_id,
        querystring: "multiple_delete",
        sub_query_string: "REMINDERS_SERVICE",
      };
    } else if (path.includes("renewals")) {
      return {
        //screenName: screenName,
        delete_ids: rowData.reminder_id,
        querystring: "multiple_delete",
        sub_query_string: "REMINDERS_EXPENSE",
      };
    }
  };

  //let editData = {};

  const getEditInfo = () => {
    if (path.includes("inspectiontasks")) {
      return {
        route: "/inspection/inspectiontasks/addnew",
        screen: "inspectiontasks",
        rowData: rowData,
      };
    }
    if (path.includes("inspectionforms")) {
      return {
        route: "/inspection/inspectionforms/addnew",
        screen: "inspectionforms",
        rowData: rowData,
      };
    }
    if (path.includes("inspectionscheduling")) {
      return {
        route: "/inspection/inspectionscheduling/addnew",
        screen: "inspectionschedules",
        rowData: rowData,
      };
    }
    if (path.includes("maintenancereminders")) {
      return {
        route: "/reminders/maintenancereminders/addnew",
        screen: "maintenancereminders",
        rowData: rowData,
      };
    }
    if (path.includes("renewals")) {
      return {
        route: "/reminders/renewals/addnew",
        screen: "renewals",
        rowData: rowData,
      };
    }
    if (path.includes("servicetasks")) {
      return {
        route: "/services/servicetasks/addnew",
        screen: "servicetasks",
        rowData: rowData,
      };
    }
    if (path.includes("expensetasks")) {
      return {
        route: "/expenses/expensetasks/addnew",
        screen: "expensetasks",
        rowData: rowData,
      };
    }
  };

  // if (path.includes("maintenancereminders") || path.includes("renewals")) {
  //   showCreateNew = true;
  // }

  const handleEdit = () => {
    const editData = getEditInfo();
    //console.log(editData);
    history.push(editData.route, {
      type: "edit",
      route: editData.route,
      screen: editData.screen,
      rowData: editData.rowData,
    });
  };

  const handleDelete = () => {
    setShowDeletePopup(true);
    setToggleOptions(false);

    // if (showDeletePopup === true) {
    //   setToggleOptions(false);
    // }
  };

  //Applicable only for Daily Time Sheet
  const handleView = () => {
    setShowZoomedDoc(true);
    setToggleOptions(false);
    // if (showZoomedDoc === true) {
    //   setToggleOptions(false);
    // }
  };

  const handleCreateService = () => {
    let mode,
      screen,
      route = "";
    if (path.includes("maintenancereminders")) {
      mode = "from-maintenance-reminders";
      screen = "maintenancereminders";
      route = "/services/services/addnew";
    }
    if (path.includes("renewals")) {
      mode = "from-renewals";
      screen = "renewals";
      route = "/expenses/expenses/addnew";
    }

    history.push(route, {
      type: "add",
      route: route,
      screen: screen,
      data: { rowData },
      mode: mode,
    });
  };

  return (
    <>
      {/* <AlwaysScrollToBottom /> */}
      <RowOptionsMenuWrap id="row-options">
        {showCreateNew &&
          (path?.includes("maintenancereminders") ||
            path?.includes("renewals")) && (
            <OptionsMenu
              id="create-service-button"
              onClick={handleCreateService}
            >
              {path?.includes("maintenancereminders")
                ? t("create_service")
                : t("create_expense")}
            </OptionsMenu>
          )}
        {path.includes("dailytimesheet") && (
          <OptionsMenu id="view-button" onClick={handleView}>
            {t("view_doc")}
          </OptionsMenu>
        )}
        {!path.includes("dailytimesheet") && showEdit && (
          <OptionsMenu id="edit-button" onClick={handleEdit}>
            {t("edit")}
          </OptionsMenu>
        )}
        <OptionsMenu id="delete-button" onClick={handleDelete}>
          {t("delete")}
        </OptionsMenu>
      </RowOptionsMenuWrap>
    </>
  );
};

export const RowOptionsMenuWrap = styled.div`
  top: 35px;
  z-index: 1000;
  position: absolute;
  width: 120px;
  border: 1px solid lightgray;
  right: 10px;
`;

export const DropdownContainer = styled(Dropdown)`
  position: unset;
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-toggle {
    color: black;
  }

  .btn-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid lightgray;
    height: 35px;
    width: 40px;
  }
`;
