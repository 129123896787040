import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { scrollbarStyles, taskBgColor } from "../../../../../../utils/palette";
import { ItemContainer, ItemLabel, ItemValue } from "..";
import { HintText, LinkSpan } from "../../../../form/FormElements";
import { NATEXT } from "../../../../../constants";
import { ActionIconContainer } from "../../../../TableElements";
import { ScrollableDiv } from "../../../../../../components/Vehicles/MaintenanceSchedulePopup";
import styled from "styled-components";

const ServiceTasksSidePanel = ({ serviceTasks, rowData }) => {
  const { t } = useTranslation("common");
  const { currency } = useSelector((state) => state.units);

  const { parts_total, labor_total, tax, discount, total } = rowData;

  //To hold the value of the corresponding service task when `${count} parts` is clicked
  const [selectedTask, setSelectedTask] = useState([]);
  const [showPartsPopup, setShowPartsPopup] = useState(false);

  const handlePartsClick = (task) => {
    setSelectedTask(task);
    setShowPartsPopup(true);
  };
  return (
    <>
      {showPartsPopup && (
        <SidePanelPartsPopup
          showPopup={showPartsPopup}
          setShowPopup={setShowPartsPopup}
          serviceTask={selectedTask}
        />
      )}
      {serviceTasks?.map((task) => {
        return (
          <Row
            className="mx-0 my-2 pt-1"
            key={task.service_task_id}
            style={{
              background: taskBgColor,
              //padding: "4px",
              //margin: "0.25rem 0",
              borderRadius: "5px",
            }}
          >
            <Col>
              <Row>
                <Col className="d-flex gap-2 align-items-center">
                  <p style={{ fontSize: "16px" }}>{task.service_task_name}</p>
                  {task?.noOfParts > 0 && (
                    <LinkSpan onClick={() => handlePartsClick(task)}>
                      {t("count_parts", { count: task.noOfParts })}
                    </LinkSpan>
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <ItemContainer>
                    <ItemLabel>{`${t("parts")} (${currency})`}</ItemLabel>
                    <ItemValue>
                      {task.partsCost ? task.partsCost?.toFixed(2) : NATEXT}
                    </ItemValue>
                  </ItemContainer>
                </Col>

                <Col md={4}>
                  <ItemContainer>
                    <ItemLabel>{`${t("labor")} (${currency})`}</ItemLabel>
                    <ItemValue>
                      {task.laborCost ? task.laborCost?.toFixed(2) : NATEXT}
                    </ItemValue>
                  </ItemContainer>
                </Col>

                <Col md={4}>
                  <ItemContainer>
                    <ItemLabel>{`${t("tc_tv")} (${currency})`}</ItemLabel>
                    <ItemValue>
                      {task.totalCost ? task.totalCost?.toFixed(2) : NATEXT}
                    </ItemValue>
                  </ItemContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      })}
      <Row
        style={{
          background: taskBgColor,
          padding: "4px",
          margin: "0.25rem 0",
          borderRadius: "5px",
        }}
      >
        <Col md={4}>
          <ItemContainer>
            <ItemLabel>{`${t("parts_total")}`}</ItemLabel>
            <ItemValue>{parts_total ? parts_total?.toFixed(2) : 0}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={4}>
          <ItemContainer>
            <ItemLabel>{`${t("labor_total")}`}</ItemLabel>
            <ItemValue>{labor_total ? labor_total?.toFixed(2) : 0}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={4}>
          <ItemContainer>
            <ItemLabel>{`${t("total")}`}</ItemLabel>
            <ItemValue>{total ? total?.toFixed(2) : 0}</ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col>
          <ItemContainer
            className="d-flex gap-2 align-items-center justify-content-end"
            //style={{ marginRight: "8px" }}
          >
            <ItemLabel
              className="m-0"
              //style={{ minWidth: "90px" }}
            >
              {t("discount")}:
            </ItemLabel>
            <ItemValue>
              {+discount ? `${discount?.toFixed(2)} ${currency}` : NATEXT}
            </ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col>
          <ItemContainer
            className="d-flex gap-2 align-items-center justify-content-end"
            //style={{ marginRight: "12px" }}
          >
            <ItemLabel
              className="m-0"
              //style={{ minWidth: "80px" }}
            >
              {t("tax")}:
            </ItemLabel>
            <ItemValue>
              {+tax ? `${tax?.toFixed(2)} ${currency}` : NATEXT}
            </ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col>
          <ItemContainer className="d-flex gap-2 align-items-center justify-content-end">
            <ItemLabel
              className="m-0"
              //style={{ minWidth: "90px" }}
            >
              {t("grand_total")}:
            </ItemLabel>
            <ItemValue
              style={{
                fontWeight: 500,
                whiteSpace: "nowrap",
              }}
            >
              {+total
                ? `${(+total - +discount + +tax)?.toFixed(2)} ${currency}`
                : NATEXT}
            </ItemValue>
          </ItemContainer>
        </Col>
      </Row>
    </>
  );
};

export default ServiceTasksSidePanel;

const SidePanelPartsPopup = ({ showPopup, setShowPopup, serviceTask }) => {
  const { t } = useTranslation("common");
  const { currency } = useSelector((state) => state.units);

  console.log(serviceTask?.batches);
  let totalPartsCost = 0;
  if (serviceTask?.batches?.length > 0) {
    totalPartsCost = serviceTask.batches.reduce(
      (prev, curr) => prev + curr?.totalCost,
      0
    );
    console.log(totalPartsCost);
  }

  return (
    <Modal show={showPopup} onHide={() => setShowPopup(!showPopup)}>
      <Modal.Header>
        {/* <p style={{ fontWeight: 500, fontSize: "16px" }}>
          Non Inspected Vehicles
        </p>
        <ActionIcon
          className="fi fi-rr-cross-small"
          onClick={() => setShowPopup(!showPopup)}
        ></ActionIcon> */}
        <h4>{serviceTask?.service_task_name}</h4>
        <div className="d-flex justify-content-end">
          <ActionIconContainer onClick={() => setShowPopup(!showPopup)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th></th>
              <th>{t("consumed")}</th>
              <th>{`${t("cost_per_unit")} (${currency})`}</th>
              <th>{`${t("tc_tv")} (${currency})`}</th>
            </tr>
          </thead>
          <tbody>
            {serviceTask?.batches?.map((batch) => (
              <tr
                key={batch.parts_batch_id}
                style={{ background: taskBgColor, padding: "1rem" }}
              >
                <td>{batch.part_name}</td>
                <td>{batch.unitsConsumed}</td>
                <td>{batch.purchase_cost_per_unit || NATEXT}</td>
                <td>{batch.totalCost || NATEXT}</td>
              </tr>
            ))}
          </tbody>
        </table> */}
        <Container className="p-0 m-0">
          <Row>
            <Col className="m-0 p-0"></Col>
            <Col className="m-0 p-0">
              <span>{t("consumed")}</span>
            </Col>
            <Col className="m-0 p-0">
              <span>{`${t("cost_per_unit")} (${currency})`}</span>
            </Col>
            <Col className="m-0 p-0">
              <span>{`${t("tc_tv")} (${currency})`}</span>
            </Col>
          </Row>
          <ScrollableRow $height={"300px"}>
            <Col className="m-0 p-0">
              {serviceTask?.batches?.map((batch) => (
                <Row
                  key={batch.parts_batch_id}
                  style={{
                    background: taskBgColor,
                    padding: "1rem 0 1rem 1rem ",
                    margin: "0.5rem 0",
                  }}
                >
                  <Col className="m-0 p-0">
                    <p style={{ fontWeight: 500 }}>{batch.part_name}</p>
                    {batch.part_number && (
                      <HintText>{batch.part_number}</HintText>
                    )}
                  </Col>
                  <Col className="m-0 p-0">
                    <p>{batch.unitsConsumed}</p>
                  </Col>
                  <Col className="m-0 p-0">
                    <p>{batch.purchase_cost_per_unit || NATEXT}</p>
                  </Col>
                  <Col className="m-0 p-0">
                    <p
                      style={{
                        paddingLeft: "1rem",
                        //paddingRight: "1rem",
                        //textAlign: "end",
                      }}
                    >
                      {batch.totalCost ? batch.totalCost.toFixed(2) : NATEXT}
                    </p>
                  </Col>
                </Row>
              ))}
            </Col>
          </ScrollableRow>
          <Row className="mt-2 mb-4">
            <Col>
              <div className="d-flex justify-content-end w-100">
                <div
                  className="d-flex gap-2"
                  style={{
                    position: "relative",
                    right: "35px",
                    //fontSize: "16px",
                  }}
                >
                  <span>{t("parts_total")}</span>
                  <p style={{ fontWeight: 500 }}>
                    {totalPartsCost ? `${totalPartsCost} ${currency}` : NATEXT}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

//styles

const ScrollableRow = styled(Row)`
  margin: 0;
  padding: 0;
  overflow: auto;
  ${(p) => p.$height && `height: ${p.$height}`};
  max-height: 400px;
  ${scrollbarStyles}
`;
