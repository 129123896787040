import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CustomLoader, FilterButton } from "../../../TableElements";
import { ItemContainer } from ".";
import { colorBlue, taskBgColor } from "../../../../../utils/palette";
import { CheckBoxInput, LinkSpan } from "../../../form/FormElements";
import styled from "styled-components";
import { Desc } from "../../../../../components/Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { post, postApi } from "../../../../../api/getUser";
import Tooltip from "../../../Tooltip";
import { useTranslation } from "react-i18next";
import getUnixTime from "date-fns/getUnixTime";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../contexts/AuthContext";
import { getDistanceFactorforOdo } from "../../../../helpers";
import IssuePopup from "../../../../../components/FaultCodes/IssuePopup";
import format from "date-fns/format";
import { addSnackbarData } from "../../../../../features/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";

const FaultCodesRightPanel = ({ rowData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const { roleId } = useSelector((state) => state.user);
  const { fetchTelematicsOdo } = useAuth();
  const vehiclesList = useSelector((state) => state.vehicles.list);
  const { distance } = useSelector((state) => state.units);

  const [selectedFaults, setSelectedFaults] = useState([]);

  const [faultsData, setFaultsData] = useState([]); //Without Issues
  const [faultsWithIssues, setFaultsWithIssues] = useState([]); // With Issues

  const [createIssueBtnLoading, setCreateIssueBtnLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [showIssuePopup, setShowIssuePopup] = useState(false);
  const [issueData, setIssueData] = useState(null);

  const { faults, veh_id } = rowData;
  //console.log(faultsData);

  const fetchFaultCodes = async () => {
    setIsLoading(true);
    setSelectedFaults([]);
    setFaultsData([]);
    setFaultsWithIssues([]);
    const apiData = {
      querystring: "get_single_vehicle_fault_codes",
      veh_id: veh_id,
    };
    const res = await post("integrations", apiData);
    console.log(res);

    if (res.success) {
      // dispatch(
      //   addSnackbarData({ msg: t("issue_created_successfully"), type: 1 })
      // );
      const { data } = res;
      console.log(data);
      const { faults } = data;
      const faultsWithIssues = faults.filter((f) => f.issue_id);
      const faultsWithoutIssues = faults.filter((f) => !f.issue_id);
      setFaultsData(faultsWithoutIssues);
      setFaultsWithIssues(faultsWithIssues);
      setIsLoading(false);
    } else {
      dispatch(addSnackbarData({ msg: t("listing_err_msg"), type: 2 }));
      setIsLoading(false);
      setFaultsData([]);
      setFaultsWithIssues([]);
    }
  };

  useEffect(() => {
    (async () => {
      // setFaultsData([]);
      // setFaultsWithIssues([]);
      // const apiData = {
      //   querystring: "get_single_vehicle_fault_codes",
      //   veh_id: veh_id,
      // };
      // const res = await post("integrations", apiData);
      // console.log(res);
      // if (res.success) {
      //   const { data } = res;
      //   console.log(data);
      //   const { faults } = data;
      //   const faultsWithIssues = faults.filter((f) => f.issue_id);
      //   const faultsWithoutIssues = faults.filter((f) => !f.issue_id);
      //   setFaultsData(faultsWithoutIssues);
      //   setFaultsWithIssues(faultsWithIssues);
      // }
      await fetchFaultCodes();
    })();
  }, [veh_id]);

  console.log(selectedFaults);

  const handleCheckboxChange = (e, fault) => {
    const { checked } = e.target;
    setSelectedFaults((prev) => {
      let temp = [...prev];
      if (checked) {
        temp.push(fault);
      } else {
        temp = temp.filter((f) => f.dtcShortCode !== fault.dtcShortCode);
      }
      return temp;
    });
  };

  const handleCreateIssue = async () => {
    setCreateIssueBtnLoading(true);
    let odoFromTelematics = 0;
    let maxOdo = 0;
    let odoVal = 0;

    const currentVehicle = vehiclesList.find((v) => v.veh_id === veh_id);

    //Fetch max odo
    const maxOdoRes = await postApi(
      {
        querystring: "getOdometerFromDate",
        sub_querystring: "",
        veh_id: veh_id,
        filter_date_start: 0,
        filter_date_end: getUnixTime(new Date()),
      },
      "dashboard"
    );

    const distanceFactor = getDistanceFactorforOdo(
      currentVehicle?.primary_meter,
      distance
    );

    if (maxOdoRes.success) {
      let [nextOdo, lastOdo] = maxOdoRes.odohint_data;
      if (lastOdo) {
        //Convert to vehicle unit
        lastOdo = lastOdo / distanceFactor;
        maxOdo = lastOdo;
      }
    }

    //Fetch odo from telematics
    if (fetchTelematicsOdo && currentVehicle.provider_type_1) {
      const odoApiData = {
        querystring: "get_current_obd_odometer",
        veh_id: veh_id,
        provider_type: 1,
      };
      const odoRes = await post("integrations", odoApiData);
      if (odoRes.success) {
        odoFromTelematics = +odoRes.data.odometer_in_meters;
      }
    }

    odoVal = Math.max(maxOdo, odoFromTelematics);
    if (odoVal) {
      //Convert back to org unit
      odoVal = odoVal * distanceFactor;
    }

    const faults = selectedFaults.map((f) => ({
      dtcShortCode: f.dtcShortCode,
      dtcDescription: f.dtcDescription,
    }));
    const apiData = {
      veh_id: veh_id,
      odo: odoVal,

      reported_date: getUnixTime(new Date()),
      querystring: "create_issues_from_faults",
      faults: faults,
      formatted_date: format(new Date(), "dd MMM yyyy"),
      //utc_offset: new Date().getTimezoneOffset(),
    };
    const res = await post("integrations", apiData);
    console.log(res);
    if (res.success) {
      setCreateIssueBtnLoading(false);
      dispatch(
        addSnackbarData({ msg: t("issue_created_successfully"), type: 1 })
      );
      await fetchFaultCodes();
    }
  };

  return (
    <>
      {showIssuePopup && (
        <IssuePopup
          showPopup={showIssuePopup}
          setShowPopup={setShowIssuePopup}
          issueData={issueData}
        />
      )}
      <Row className="mt-3">
        {faultsData.length > 0 && roleId !== 4 && (
          <Col className="mb-2" sm={12}>
            {selectedFaults.length > 0 ? (
              <FilterButton
                type="button"
                disabled={selectedFaults.length === 0 || createIssueBtnLoading}
                onClick={handleCreateIssue}
              >
                {createIssueBtnLoading ? <CustomLoader /> : t("create_issue")}
              </FilterButton>
            ) : (
              <Tooltip text={t("select_fault_code_to_create_issue")}>
                <FilterButton
                  type="button"
                  disabled={selectedFaults.length === 0}
                >
                  {t("create_issue")}
                </FilterButton>
              </Tooltip>
            )}
          </Col>
        )}
        {faultsData.length === 0 &&
          faultsWithIssues.length === 0 &&
          !isLoading && <Col>{t("no_records")}</Col>}
        {isLoading && (
          <Col>
            <Loading loading={true} />
          </Col>
        )}
        {faultsData.map((fault, i) => (
          <Col sm={12} key={i}>
            <FaultContainer>
              {roleId !== 4 && (
                <div className="align-self-start">
                  <CheckBoxInput
                    type="checkbox"
                    id={fault.dtcShortCode}
                    onChange={(e) => handleCheckboxChange(e, fault)}
                  />
                </div>
              )}
              <div className="d-flex flex-column gap-1">
                <label
                  htmlFor={fault.dtcShortCode}
                  style={{ fontWeight: 500, margin: 0, padding: 0 }}
                >
                  {fault.dtcShortCode}
                </label>
                <Desc>{fault.dtcDescription}</Desc>
              </div>
              {/* <p>{`${fault.faultCode} - ${fault.faultDesc}`}</p> */}
            </FaultContainer>
          </Col>
        ))}
        {faultsWithIssues.map((fault, i) => (
          <Col sm={12} key={i}>
            <FaultContainer className="d-flex flex-column p-2">
              <div>
                <p>{fault.dtcShortCode}</p>
                <Desc>{fault.dtcDescription}</Desc>
              </div>
              <LinkSpan
                onClick={() => {
                  setIssueData(fault?.issue_object);
                  setShowIssuePopup(true);
                }}
              >
                {t("issue_created")}
              </LinkSpan>
            </FaultContainer>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default FaultCodesRightPanel;

const Loading = () => {
  return (
    <LoaderWrap>
      <Loader>
        <path fill={colorBlue} d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </Loader>
    </LoaderWrap>
  );
};

//styles
const FaultContainer = styled.div`
  display: flex;
  //flex-direction: column;
  gap: 0.3rem;
  background: ${taskBgColor};
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 5px;
`;

const Loader = styled.svg`
  animation: linear load 2s infinite;
  margin: auto;
  width: 26px;
  height: 26px;

  @keyframes load {
    from {
      transform: rotate(0deg) scale(0.75);
    }
    to {
      transform: rotate(360deg) scale(0.75);
    }
  }
`;

export const LoaderWrap = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  width: auto;
  height: auto;
`;
