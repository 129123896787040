import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";

import { PhotoUploadComponent } from "../UserManagement/UserDetailsForm";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import Switch from "../../shared/components/form/Switch/Switch";
import {
  FormGroupLabel,
  FormItem,
  FormValueContainer,
  UnitSpan,
} from "../../shared/components/form/FormElements";
import FormField from "../../shared/components/form/FormField";
import { colorSwitchActive, colorYellowNew } from "../../utils/palette";
import { getUnits, post, postApi } from "../../api/getUser";
import {
  Desc,
  SingleValueOption,
} from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import Error from "@/shared/components/form/Error";
import CheckboxInputComponent from "../../shared/components/form/CheckboxInputComponent";
import GroupsFormPopup from "../Settings/Groups/GroupsFormPopup";
import InfoPopup from "../../shared/components/InfoPopup";
import SubscribePopup from "../../shared/components/SubscribePopup";
import { useAuth } from "../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../shared/components/SubscribeTooltipIcon";
import {
  getDistanceFactorforOdo,
  getTranslatedPMUnit,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
} from "../../shared/helpers";
import { CustomLoader } from "../../shared/components/TableElements";

import vinIcon from "../../../src/shared/img/vinCarSearch.png";
import styled from "styled-components";
import Snackbar from "../../shared/components/Snackbar";
import Tooltip from "../../shared/components/Tooltip";
import { s3Url } from "../../shared/constants";

const VehicleDetailsForm = ({
  rowData,
  type,
  //vehicleInfoRef,
  userAssignedUpdated,
  setUserAssignedUpdated,
  setUsersAssigned,
  vehiclePhoto,
  setVehiclePhoto,
}) => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation("common");
  const { currency } = useSelector((state) => state.units);

  const users = useSelector((state) => state.users.list);
  const allGroups = useSelector((state) => state.groups.list)?.filter(
    (g) => g.group_id
  );
  const { country } = useSelector((state) => state.user);
  const { distance } = useSelector((state) => state.units);

  const { tag } = useAuth();

  // const [vehiclePhoto, setVehiclePhoto] = useState("");

  //const [outOfService, setOutOfService] = useState(false);

  const fuelTypeOptions = [
    {
      value: "Petrol",
      label: t("petrol_const"),
    },
    {
      value: "Diesel",
      label: t("diesel_const"),
    },
  ];

  //Hook form variables
  const vehicleName = watch("vehicleName");
  const licenseNo = watch("licenseNo");
  const vehicleType = watch("vehicleType");
  const vin = watch("vin");
  const status = watch("status");

  const make = watch("make");
  const group = watch("group");
  const model = watch("model");
  const year = watch("year");
  const fuelType = watch("fuelType");
  const primaryMeter = watch("primaryMeter");
  const purchasePrice = watch("purchasePrice");
  const startingOdo = watch("startingOdo");
  const outOfService = watch("outOfService");

  //States for Adding new group
  const [newGroupInfo, setNewGroupInfo] = useState(null);
  const [showAddGroupPopup, setShowAddGroupPopup] = useState(false);

  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([
    { label: t("veh_type_value_truck"), value: t("veh_type_value_truck") },
    { label: t("veh_type_value_car"), value: t("veh_type_value_car") },
    { label: t("veh_type_value_pickup"), value: t("veh_type_value_pickup") },
    { label: t("veh_type_value_bus"), value: t("veh_type_value_bus") },
    { label: t("veh_type_value_trailer"), value: t("veh_type_value_trailer") },
    { label: t("veh_type_value_van"), value: t("veh_type_value_van") },
    { label: t("veh_type_value_tow"), value: t("veh_type_value_tow") },
    {
      label: t("veh_type_value_motorcycle"),
      value: t("veh_type_value_motorcycle"),
    },
    {
      label: t("veh_type_value_generator"),
      value: t("veh_type_value_generator"),
    },
    { label: t("veh_type_value_mover"), value: t("veh_type_value_mover") },
    { label: t("veh_type_value_loader"), value: t("veh_type_value_loader") },
    { label: t("veh_type_value_mixer"), value: t("veh_type_value_mixer") },
  ]);

  const [primaryMeterOptions, setPrimaryMeterOptions] = useState([]);

  //To show a popup that inactivating a vehicle will delete it's reminders
  const [showVehicleInactivePopup, setShowVehicleInactivePopup] =
    useState(false);

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const isDisabled = type === "edit" && !status;

  const [showVinIcon, setShowVinIcon] = useState(false);
  const [vinIconLoading, setVinIconLoading] = useState(false);
  const autoVinCountries = ["United States", "Canada"];
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({ message: "", type: 1 });

  //Initial Effect
  useEffect(() => {
    //Logic to fetch custom vehicle types
    (async () => {
      const res = await postApi(
        { querystring: "get_vehicle_types" },
        "commonNew"
      );

      if (res.success) {
        const { user_data } = res;

        //Set default type (Truck)
        // if (type !== "edit") {
        //   setValue("vehicleType", {
        //     label: t("veh_type_value_truck"),
        //     value: t("veh_type_value_truck"),
        //   });
        // }

        setVehicleTypeOptions((prev) => {
          const temp = [...prev];
          user_data.forEach((obj) => {
            temp.push({
              label: obj.veh_type,
              value: obj.veh_type,
            });
          });
          return temp;
        });
      }
    })();

    //Logic to get units
    (async () => {
      const unitsRes = await getUnits();
      //console.log("unitsRes", unitsRes);
      if (unitsRes.success) {
        const {
          meta_data: { units },
        } = unitsRes;

        let distanceUnits = units
          .filter((u) => u.unit_type === "distance")
          .map((u) => ({
            ...u,
            label: getTranslatedPMUnit(u.unit_name, t),
            value: u.unit_name,
          }));

        if (type !== "edit") {
          setValue(
            "primaryMeter",
            distanceUnits.find((d) => d.value === distance)
          );
        }

        setPrimaryMeterOptions(distanceUnits);
      }
    })();

    //Set Default Fuel Type
    if (type !== "edit") {
      setValue("fuelType", fuelTypeOptions[1]);
    }

    //Fetch country from orgData and check if country is United States, If so, show vin search icon
    //Only in Add Vehicle
    // if (type !== "edit") {
    //   (async () => {
    //     const fetchOrgDetailsApiData = {
    //       source: "web",
    //       last_updated: "0",
    //       org_last_updated: "0",
    //       allusers_last_updated: "454545454545454545",
    //     };
    //     const fetchOrgDetailsRes = await postApi(
    //       fetchOrgDetailsApiData,
    //       "user",
    //       true,
    //       true
    //     );

    //     if (fetchOrgDetailsRes.success) {
    //       const { org_data } = fetchOrgDetailsRes;
    //       const orgData = org_data[0];
    //       const { country } = orgData;
    //       if (country === "United States" || country === "Canada") {
    //         setShowVinIcon(true);
    //       }
    //     }
    //   })();
    // }
  }, []);

  //Update vehicleInfoRef on change of local fields
  // useEffect(() => {
  //   vehicleInfoRef.current = {
  //     vehicleName: vehicleName,
  //     licenseNo: licenseNo,
  //     vehicleType: vehicleType,
  //     vin: vin,
  //     make: make,
  //     group: group,
  //     model: model,
  //     vehiclePhoto: vehiclePhoto,
  //     status: status,
  //     year: year,
  //     fuelType: fuelType,
  //     primaryMeter: primaryMeter,
  //     purchasePrice: purchasePrice,
  //     startingOdo: startingOdo,
  //     outOfService: outOfService,
  //   };
  // }, [
  //   vehicleName,
  //   licenseNo,
  //   vehicleType,
  //   vin,
  //   make,
  //   group,
  //   model,
  //   vehiclePhoto,
  //   status,
  //   year,
  //   fuelType,
  //   primaryMeter,
  //   purchasePrice,
  //   startingOdo,
  //   outOfService,
  // ]);

  //Effect to update fields on edit
  useEffect(() => {
    if (type === "edit" && rowData) {
      const {
        veh_id,
        name,
        license_no,
        make,
        model,
        veh_type,
        group_id,
        year,
        fuel_type,
        veh_active,
        vin_no,
        primary_meter,
        starting_odo,
        veh_img,
        secondary_status,
        purchase_price,
        org_id,
      } = rowData;

      setValue("vehicleName", name);
      setValue("licenseNo", license_no);
      // setValue(
      //   "vehicleType",
      //   vehicleTypeOptions.find((t) => t.value === veh_type)
      // );
      setValue("vehicleType", {
        label: veh_type,
        value: veh_type,
      });
      setValue("vin", vin_no);
      setValue("make", make);
      setValue("model", model);
      setValue("year", year || "");
      setValue(
        "group",
        allGroups?.find((g) => g.group_id === group_id)
      );
      //Setting Status
      if (veh_active === 1 || (veh_active === 0 && secondary_status === 1)) {
        setValue("status", true);
      } else {
        setValue("status", false);
      }
      if (fuel_type) {
        setValue(
          "fuelType",
          fuelTypeOptions.find((f) => f.value === fuel_type)
        );
      } else {
        setValue("fuelType", fuelTypeOptions[1]);
      }
      setValue(
        "primaryMeter",
        primaryMeterOptions.find((f) => f.value === primary_meter)
      );

      const distanceFactor = getDistanceFactorforOdo(primary_meter, distance);
      const odoUpd = starting_odo
        ? (+starting_odo / distanceFactor)?.toFixed(2)
        : "";
      setValue("startingOdo", odoUpd);
      setValue("purchasePrice", purchase_price);

      setValue("outOfService", secondary_status === 1);
      //setOutOfService(secondary_status === 1);

      //Logic to set Vehicle Image
      if (veh_img) {
        const imageUrl = `${s3Url}/org_${org_id}/vehicle_${veh_id}/${veh_img}`;

        setVehiclePhoto({ url: imageUrl, imgName: veh_img });
      } else {
        setVehiclePhoto("");
      }
    }
  }, [rowData, primaryMeterOptions, vehicleTypeOptions]);

  //If out of service is checked, set status to Inactive
  useEffect(() => {
    if (outOfService) {
      //setStatus(false);
      setValue("status", true);
    }
    // else {
    //   setValue("status", true);
    // }
  }, [outOfService]);

  //Set newly created group from Add Group Popup
  useEffect(() => {
    if (newGroupInfo?.id) {
      const newGroup = allGroups?.find((g) => g.group_id === newGroupInfo?.id);
      console.log("New Group", newGroup);
      if (newGroup) {
        setNewGroupInfo(null);
      }
      setValue("group", newGroup);
    }
  }, [newGroupInfo?.id, allGroups]);

  //Handler for creating new Vehicle Type
  const handleCreateVehicleType = (data) => {
    const newType = { label: data, value: data };
    setVehicleTypeOptions((prev) => {
      let temp = [...prev];
      temp.push(newType);
      return temp;
    });
    setValue("vehicleType", newType);
  };

  //Handler for creating new group
  const handleCreateGroup = (data) => {
    setNewGroupInfo({ name: data, id: "" });
    setShowAddGroupPopup(true);
  };

  //Handler for toggling switch
  const handleSwitchToggle = (onChange, value) => {
    if (!value) {
      if (outOfService) {
        setValue("outOfService", false);
        //setOutOfService(false);
      }
      setShowVehicleInactivePopup(true);
    }

    //Remove vehicles assigned when user becomes inactive
    if (!value) {
      setUsersAssigned([]);
      if (!userAssignedUpdated) {
        setUserAssignedUpdated(true);
      }
    }
    onChange(value);
  };

  //Function to call api and update data using vin
  const handleAddDataFromVin = async () => {
    try {
      if (!vin || vin?.length !== 17) {
        setError(
          "vin",
          { type: "custom", message: t("invalid_vin_err_msg") },
          { shouldFocus: true }
        );
        return;
      }

      if (vin && vin?.length === 17) {
        clearErrors("vin");
        setVinIconLoading(true);
        const vehicleDataRes = await post("integrations", {
          querystring: "get_vehicle_info_from_vin",
          vin_no: vin,
        });
        console.log(vehicleDataRes);
        if (vehicleDataRes?.success) {
          const {
            data: {
              make,
              model,
              year,
              vehicle_type,
              purchase_price,
              fuel_type,
            },
          } = vehicleDataRes;

          if (
            !make &&
            !model &&
            !year &&
            !vehicle_type &&
            !purchase_price &&
            !fuel_type
          ) {
            setVinIconLoading(false);
            setSnackbarData({
              message: t("unable_to_fetch_vin_info"),
              type: 2,
            });
            setShowSnackbar(true);
            setTimeout(() => {
              setShowSnackbar(false);
              setSnackbarData({ message: "", type: 1 });
            }, 2000);
            return;
          }

          if (make) {
            setValue("make", make);
          }

          if (model) {
            setValue("model", model);
          }

          if (+year) {
            setValue("year", +year);
          }

          if (+purchase_price) {
            setValue("make", +purchase_price);
          }

          if (vehicle_type) {
            let existingType = vehicleTypeOptions.find(
              (type) =>
                type.value?.toLocaleLowerCase() ===
                vehicle_type?.toLocaleLowerCase()
            );
            if (existingType) {
              setValue("vehicleType", existingType);
            } else {
              let newType = { label: vehicle_type, value: vehicle_type };
              setVehicleTypeOptions((prev) => {
                let temp = [...prev];
                temp.push(newType);
                return temp;
              });
              setValue("vehicleType", newType);
            }
          }

          if (fuel_type) {
            let existingFuelType = fuelTypeOptions.find(
              (type) =>
                type.value?.toLocaleLowerCase() ===
                fuel_type?.toLocaleLowerCase()
            );
            setValue("fuelType", existingFuelType);
          }

          setVinIconLoading(false);
          setSnackbarData({
            message: t("fetched_data_from_vin_msg"),
            type: 1,
          });
          setShowSnackbar(true);
          setTimeout(() => {
            setShowSnackbar(false);
            setSnackbarData({ message: "", type: 1 });
          }, 3000);
        } else {
          setVinIconLoading(false);
          setSnackbarData({
            message: t("unable_to_fetch_vin_info"),
            type: 2,
          });
          setShowSnackbar(true);
          setTimeout(() => {
            setShowSnackbar(false);
            setSnackbarData({ message: "", type: 1 });
          }, 2000);
          return;
        }
      }
    } catch (e) {
      setVinIconLoading(false);
      setSnackbarData({
        message: t("listing_err_msg"),
        type: 2,
      });
      setShowSnackbar(true);
      return;
    }
  };

  return (
    <>
      {showSnackbar && (
        <Snackbar
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
          message={snackbarData.message}
          snackbarType={snackbarData.type}
        />
      )}
      {showVehicleInactivePopup && (
        <InfoPopup
          showPopup={showVehicleInactivePopup}
          setShowPopup={setShowVehicleInactivePopup}
          message={t("inactive_vehicle_delete_reminders_msg")}
          icon={{
            color: colorYellowNew,
            className: "fi fi-rr-triangle-warning",
          }}
        />
      )}
      {showAddGroupPopup && (
        <GroupsFormPopup
          showPopup={showAddGroupPopup}
          setShowPopup={setShowAddGroupPopup}
          newGroupInfo={newGroupInfo}
          setNewGroupInfo={setNewGroupInfo}
          mode={"from-add-vehicle"}
        />
      )}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}
      <Container className="pt-2 pb-4">
        <Row>
          <Col md={2} className="pt-2 ">
            <PhotoUploadComponent
              photo={vehiclePhoto}
              setPhoto={setVehiclePhoto}
              rowData={rowData}
              isDisabled={isDisabled}
            />
          </Col>
          <Col md={8}>
            {type === "edit" &&
              rowData?.veh_active == 0 &&
              rowData?.secondary_status == 2 && (
                <Row className="pt-2">
                  <Col>
                    <Desc>{t("veh_imported_from_telematics_msg")}</Desc>
                  </Col>
                </Row>
              )}
            <Row>
              <Col md={5}>
                <FormGroupLabel>{t("veh_name")}</FormGroupLabel>
                <FormItem
                  disabled={isDisabled}
                  //tabIndex={0}
                >
                  <FormField
                    name="vehicleName"
                    control={control}
                    component="input"
                    rules={{ required: t("required_err") }}
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("veh_name")}
                    style={formInputStyles}
                    disabled={isDisabled}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.vehicleName && (
                  <Error error={errors.vehicleName.message} />
                )}
              </Col>
              <Col md={2}></Col>
              <Col md={5}>
                <FormGroupLabel>{t("license_number")}</FormGroupLabel>
                <FormItem
                  disabled={isDisabled}
                  //tabIndex={0}
                >
                  <FormField
                    name="licenseNo"
                    control={control}
                    component="input"
                    //rules={{ required: t("required_err") }}
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("license_number")}
                    style={formInputStyles}
                    disabled={isDisabled}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.licenseNo && (
                  <Error error={errors.licenseNo.message} />
                )}
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <FormGroupLabel>{t("veh_type")}</FormGroupLabel>
                <Controller
                  name="vehicleType"
                  //defaultValue={vehicleTypeOptions[0]}
                  control={control}
                  //errors={errors}
                  render={({ field: { onChange, value, ref } }) => (
                    <CreatableSelect
                      options={vehicleTypeOptions}
                      //value={vehicles.find((c) => c.value === value)}
                      value={value}
                      onChange={onChange}
                      onCreateOption={handleCreateVehicleType}
                      placeholder={t("veh_type_placeholder")}
                      styles={formStyles}
                      isDisabled={isDisabled}
                      components={{ Option: SingleValueOption }}
                      id={"vehicle-type"}
                    />
                  )}
                  rules={{ required: t("required_err") }}
                />

                {errors?.vehicleType && (
                  <Error error={errors.vehicleType.message} />
                )}
              </Col>
              <Col md={2}></Col>

              <Col md={5}>
                <FormGroupLabel>{t("vin")}</FormGroupLabel>
                <FormItem
                  disabled={isDisabled}
                  //tabIndex={0}
                >
                  <FormField
                    name="vin"
                    control={control}
                    component="input"
                    //rules={{ required: t("required_err") }}
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("vin")}
                    style={formInputStyles}
                    disabled={isDisabled}
                    //onKeyUp={handleSIOdoChange}
                  />

                  {autoVinCountries.includes(country) && (
                    <div className="mx-1">
                      {vinIconLoading ? (
                        <CustomLoader />
                      ) : (
                        <Tooltip text={t("fetch_details_from_vin")}>
                          <VinImg
                            src={vinIcon}
                            alt="vin-icon"
                            width={20}
                            height={20}
                            onClick={handleAddDataFromVin}
                            //$disabled={!vin || vin?.length !== 17}
                          />
                        </Tooltip>
                      )}
                    </div>
                  )}
                </FormItem>

                {errors?.vin && <Error error={errors.vin.message} />}
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <FormGroupLabel>{t("make")}</FormGroupLabel>
                <FormItem
                  disabled={isDisabled}
                  //tabIndex={0}
                >
                  <FormField
                    name="make"
                    control={control}
                    component="input"
                    rules={{ required: t("required_err") }}
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("make")}
                    style={formInputStyles}
                    disabled={isDisabled}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.make && <Error error={errors.make.message} />}
              </Col>
              <Col md={2}></Col>

              <Col md={5}>
                <FormGroupLabel>
                  {t("group")}
                  {tag === "free-user" && (
                    <SubscribeTooltipIcon message={t("subscribe_msg")} />
                  )}
                </FormGroupLabel>
                <Controller
                  name="group"
                  control={control}
                  //errors={errors}
                  render={({ field: { onChange, value, ref } }) => (
                    <CreatableSelect
                      options={allGroups}
                      onMenuOpen={() => {
                        if (tag === "free-user") {
                          setShowSubscribePopup(true);
                          return;
                        }
                      }}
                      //value={vehicles.find((c) => c.value === value)}
                      value={value}
                      onChange={onChange}
                      onCreateOption={handleCreateGroup}
                      //onCreateOption={handleCreate}
                      placeholder={t("group_placeholder")}
                      styles={formStyles}
                      isDisabled={isDisabled}
                      components={{
                        Option: SingleValueOption,
                      }}
                      customProps={{ type: "group" }}
                    />
                  )}
                  //rules={{ required: t("required_err") }}
                />

                {errors?.group && <Error error={errors.group.message} />}
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <FormGroupLabel>{t("model")}</FormGroupLabel>
                <FormItem
                  disabled={isDisabled}
                  //tabIndex={0}
                >
                  <FormField
                    name="model"
                    control={control}
                    component="input"
                    rules={{ required: t("required_err") }}
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("model")}
                    style={formInputStyles}
                    disabled={isDisabled}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.model && <Error error={errors.model.message} />}
              </Col>
              <Col md={2}></Col>

              <Col md={5}>
                <FormGroupLabel>{t("status")}</FormGroupLabel>
                <Controller
                  name="status"
                  control={control}
                  //errors={errors}
                  defaultValue={true}
                  render={({ field: { onChange, value, ref } }) => (
                    <div
                      style={{ height: "34px" }}
                      className="d-flex align-items-center gap-2"
                    >
                      <Switch
                        id={"vehicle-status-switch"}
                        isOn={value}
                        onColor={colorSwitchActive}
                        handleToggle={(e) =>
                          handleSwitchToggle(onChange, e.target.checked)
                        }
                      />
                      <span>{status ? t("active") : t("inactive")}</span>
                    </div>
                  )}
                  //rules={{ required: t("required_err") }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <FormGroupLabel>{t("year")}</FormGroupLabel>
                <FormItem
                  disabled={isDisabled}
                  //tabIndex={0}
                >
                  <FormField
                    name="year"
                    control={control}
                    component="input"
                    //rules={{ required: t("required_err") }}
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("year")}
                    style={formInputStyles}
                    type="number"
                    onKeyDown={handleNegative}
                    onWheel={handleNumberInputOnWheelPreventChange}
                    disabled={isDisabled}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.year && <Error error={errors.year.message} />}
              </Col>
              <Col md={2}></Col>

              <Col>
                {/* <FormGroupLabel style={{ height: "0.9rem" }}></FormGroupLabel> */}
                <FormValueContainer style={{ paddingTop: "3.5rem" }}>
                  {/* <CheckboxInputComponent
                    label={t("vehicle_out_of_service_label")}
                    checked={outOfService}
                    setChecked={setOutOfService}
                    id={"mark-veh-out-of-service"}
                  /> */}
                  <Col md={12}>
                    <Controller
                      name="outOfService"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <CheckboxInputComponent
                          label={t("vehicle_out_of_service_label")}
                          checked={value}
                          onChange={onChange}
                          id={"out-of-service-label"}
                        />
                      )}
                    />

                    {errors?.outOfService && (
                      <Error error={errors.outOfService.message} />
                    )}
                  </Col>
                </FormValueContainer>
              </Col>
            </Row>
            {type !== "edit" ? (
              <>
                <hr className="mt-4 mb-3" />

                <Row>
                  <Col md={12}>
                    <Controller
                      name="recommendMaintSchedule"
                      control={control}
                      defaultValue={true}
                      render={({ field: { onChange, value, ref } }) => (
                        <CheckboxInputComponent
                          label={t("recommend_maint_schedule_for_vehicle_msg")}
                          checked={value}
                          onChange={onChange}
                          id={"recommend-maint-schedule-for-vehicle-label"}
                        />
                      )}
                      //rules={{ required: t("required_err") }}
                    />

                    {errors?.recommendMaintSchedule && (
                      <Error error={errors.recommendMaintSchedule.message} />
                    )}
                  </Col>
                </Row>

                <hr className="mt-3 mb-2" />
              </>
            ) : (
              <hr className="mt-4 mb-2" />
            )}

            <Row>
              <Col md={5}>
                <FormGroupLabel>{t("fuel_type")}</FormGroupLabel>
                <Controller
                  name="fuelType"
                  control={control}
                  //defaultValue={fuelTypeOptions[1]}
                  //errors={errors}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      options={fuelTypeOptions}
                      //value={vehicles.find((c) => c.value === value)}
                      value={value}
                      onChange={onChange}
                      //onCreateOption={handleCreate}
                      placeholder={t("fuel_type")}
                      styles={formStyles}
                      isDisabled={isDisabled}
                    />
                  )}
                  rules={{ required: t("required_err") }}
                />

                {errors?.fuelType && <Error error={errors.fuelType.message} />}
              </Col>
              <Col md={2}></Col>

              <Col md={5}>
                <FormGroupLabel>{t("primary_meter")}</FormGroupLabel>
                <Controller
                  name="primaryMeter"
                  control={control}
                  //errors={errors}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      options={primaryMeterOptions}
                      //value={vehicles.find((c) => c.value === value)}
                      value={value}
                      onChange={onChange}
                      //onCreateOption={handleCreate}
                      placeholder={t("primary_meter")}
                      styles={formStyles}
                      isDisabled={isDisabled}
                    />
                  )}
                  rules={{ required: t("required_err") }}
                />

                {errors?.primaryMeter && (
                  <Error error={errors.primaryMeter.message} />
                )}
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <FormGroupLabel>{t("purchase_price")}</FormGroupLabel>
                <FormItem
                  disabled={isDisabled}
                  //tabIndex={0}
                >
                  <FormField
                    name="purchasePrice"
                    control={control}
                    component="input"
                    type="number"
                    onKeyDown={handleNegative}
                    onWheel={handleNumberInputOnWheelPreventChange}
                    //rules={{ required: t("required_err") }}
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("purchase_price")}
                    style={formInputStyles}
                    disabled={isDisabled}
                    //onKeyUp={handleSIOdoChange}
                  />
                  <UnitSpan>{currency}</UnitSpan>
                </FormItem>

                {errors?.purchasePrice && (
                  <Error error={errors.purchasePrice.message} />
                )}
              </Col>
              <Col md={2}></Col>

              <Col md={5}>
                <FormGroupLabel>{t("starting_odo")}</FormGroupLabel>
                <FormItem
                  disabled={isDisabled}
                  //tabIndex={0}
                >
                  <FormField
                    name="startingOdo"
                    control={control}
                    component="input"
                    type="number"
                    onKeyDown={handleNegative}
                    onWheel={handleNumberInputOnWheelPreventChange}
                    //rules={{ required: t("required_err") }}
                    defaultValue=""
                    //isAboveError={isAboveError}
                    placeholder={t("starting_odo")}
                    style={formInputStyles}
                    disabled={isDisabled}
                    //onKeyUp={handleSIOdoChange}
                  />
                </FormItem>

                {errors?.startingOdo && (
                  <Error error={errors.startingOdo.message} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VehicleDetailsForm;

//styles
const VinImg = styled.img`
  position: relative;
  bottom: 2px;
  cursor: pointer;
  //right: 4px;
`;
