export const NATEXT = "-";

export const ALBUM_BUCKET_NAME = "simplyfleet.app";
export const BUCKET_REGION = "us-east-2";
export const IDENTITY_POOL_ID =
  "us-east-2:c4c81433-eac9-4733-9967-1e8617b51b3c";
export const initialLoadDelay = 1000;

export const countryCurrencyMapping = [
  ["Afghanistan", "AFN"],
  ["Albania", "ALL"],
  ["Algeria", "DZD"],
  ["Andorra", "EUR"],
  ["Angola", "AOA"],
  ["Anguilla", "XCD"],
  ["Antarctica", "XCD"],
  ["Antigua and Barbuda", "XCD"],
  ["Argentina", "ARS"],
  ["Armenia", "AMD"],
  ["Aruba", "AWG"],
  ["Australia", "AUD"],
  ["Austria", "EUR"],
  ["Azerbaijan", "AZN"],
  ["Bahamas", "BSD"],
  ["Bahrain", "BHD"],
  ["Bangladesh", "BDT"],
  ["Barbados", "BBD"],
  ["Belarus", "BYR"],
  ["Belgium", "EUR"],
  ["Belize", "BZD"],
  ["Benin", "XOF"],
  ["Bermuda", "BMD"],
  ["Bhutan", "BTN"],
  ["Bolivia", "BOB"],
  ["Bosnia and Herzegovina", "BAM"],
  ["Botswana", "BWP"],
  ["Bouvet Island", "NOK"],
  ["Brazil", "BRL"],
  ["British Indian Ocean Territory", "GBP"],
  ["Brunei", "BND"],
  ["Bulgaria", "BGN"],
  ["Burkina Faso", "XOF"],
  ["Burundi", "BIF"],
  ["Cambodia", "KHR"],
  ["Cameroon", "XAF"],
  ["Canada", "CAD"],
  ["Cape Verde", "CVE"],
  ["Cayman Islands", "KYD"],
  ["Central African Republic", "XAF"],
  ["Chile", "CLP"],
  ["China", "CNY"],
  ["Christmas Island", "AUD"],
  ["Cocos (Keeling) Islands", "AUD"],
  ["Colombia", "COP"],
  ["Comoros", "KMF"],
  ["Congo", "XAF"],
  ["Cook Islands", "NZD"],
  ["Costa Rica", "CRC"],
  ["Croatia", "HRK"],
  ["Cuba", "CUP"],
  ["Cyprus", "EUR"],
  ["Czech Republic", "CZK"],
  ["Denmark", "DKK"],
  ["Djibouti", "DJF"],
  ["Dominica", "XCD"],
  ["Dominican Republic", "DOP"],
  ["Ecuador", "ECS"],
  ["Egypt", "EGP"],
  ["El Salvador", "SVC"],
  ["England", "GBP"],
  ["Equatorial Guinea", "XAF"],
  ["Eritrea", "ERN"],
  ["Estonia", "EUR"],
  ["Ethiopia", "ETB"],
  ["Faroe Islands", "DKK"],
  ["Fiji Islands", "FJD"],
  ["Finland", "EUR"],
  ["France", "EUR"],
  ["French Guiana", "EUR"],
  ["French Southern territories", "EUR"],
  ["Gabon", "XAF"],
  ["Gambia", "GMD"],
  ["Georgia", "GEL"],
  ["Germany", "EUR"],
  ["Ghana", "GHS"],
  ["Gibraltar", "GIP"],
  ["Greece", "EUR"],
  ["Greenland", "DKK"],
  ["Grenada", "XCD"],
  ["Guatemala", "QTQ"],
  ["Guinea", "GNF"],
  ["Guinea", "Bissau", "CFA"],
  ["Guyana", "GYD"],
  ["Haiti", "HTG"],
  ["Heard Island and McDonald Islands", "AUD"],
  ["Honduras", "HNL"],
  ["Hong Kong", "HKD"],
  ["Hungary", "HUF"],
  ["Iceland", "ISK"],
  ["India", "INR"],
  ["Indonesia", "IDR"],
  ["Iran", "IRR"],
  ["Iraq", "IQD"],
  ["Ireland", "EUR"],
  ["Israel", "ILS"],
  ["Italy", "EUR"],
  ["Ivory Coast", "XOF"],
  ["Jamaica", "JMD"],
  ["Japan", "JPY"],
  ["Jordan", "JOD"],
  ["Kazakstan", "KZT"],
  ["Kenya", "KES"],
  ["Kiribati", "AUD"],
  ["Kuwait", "KWD"],
  ["Kyrgyzstan", "KGS"],
  ["Laos", "LAK"],
  ["Latvia", "LVL"],
  ["Lebanon", "LBP"],
  ["Lesotho", "LSL"],
  ["Liberia", "LRD"],
  ["Liechtenstein", "CHF"],
  ["Lithuania", "LTL"],
  ["Luxembourg", "EUR"],
  ["Macao", "MOP"],
  ["Macedonia", "MKD"],
  ["Madagascar", "MGF"],
  ["Malawi", "MWK"],
  ["Malaysia", "MYR"],
  ["Maldives", "MVR"],
  ["Mali", "XOF"],
  ["Malta", "EUR"],
  ["Mauritania", "MRO"],
  ["Mauritius", "MUR"],
  ["Mayotte", "EUR"],
  ["Mexico", "MXN"],
  ["Moldova", "MDL"],
  ["Monaco", "EUR"],
  ["Mongolia", "MNT"],
  ["Montserrat", "XCD"],
  ["Morocco", "MAD"],
  ["Mozambique", "MZN"],
  ["Myanmar", "MMK"],
  ["Namibia", "NAD"],
  ["Nauru", "AUD"],
  ["Nepal", "NPR"],
  ["Netherlands", "EUR"],
  ["Netherlands Antilles", "NGA"],
  ["New Zealand", "NZD"],
  ["Nicaragua", "NIO"],
  ["Niger", "XOF"],
  ["Nigeria", "NGN"],
  ["Niue", "NZD"],
  ["Norfolk Island", "AUD"],
  ["North Korea", "KPW"],
  ["Northern Ireland", "GBP"],
  ["Norway", "NOK"],
  ["Oman", "OMR"],
  ["Pakistan", "PKR"],
  ["Panama", "PAB"],
  ["Papua New Guinea", "PGK"],
  ["Paraguay", "PYG"],
  ["Peru", "PEN"],
  ["Philippines", "PHP"],
  ["Poland", "PLN"],
  ["Portugal", "EUR"],
  ["Qatar", "QAR"],
  ["Romania", "RON"],
  ["Russia", "RUR"],
  ["Rwanda", "RWF"],
  ["Saint Helena", "SHP"],
  ["Saint Kitts and Nevis", "XCD"],
  ["Saint Lucia", "XCD"],
  ["Saint Pierre and Miquelon", "EUR"],
  ["Saint Vincent and the Grenadines", "XCD"],
  ["Samoa", "WST"],
  ["San Marino", "EUR"],
  ["Sao Tome and Principe", "STD"],
  ["Saudi Arabia", "SAR"],
  ["Scotland", "GBP"],
  ["Senegal", "XOF"],
  ["Seychelles", "SCR"],
  ["Sierra Leone", "SLL"],
  ["Singapore", "SGD"],
  ["Slovakia", "EUR"],
  ["Slovenia", "EUR"],
  ["Solomon Islands", "SBD"],
  ["Somalia", "SOS"],
  ["South Africa", "ZAR"],
  ["South Georgia and the South Sandwich Islands", "GBP"],
  ["South Korea", "KRW"],
  ["South Sudan", "SSP"],
  ["Spain", "EUR"],
  ["SriLanka", "LKR"],
  ["Sudan", "SDG"],
  ["Suriname", "SRD"],
  ["Swaziland", "SZL"],
  ["Sweden", "SEK"],
  ["Switzerland", "CHF"],
  ["Syria", "SYP"],
  ["Tajikistan", "TJS"],
  ["Tanzania", "TZS"],
  ["Thailand", "THB"],
  ["Togo", "XOF"],
  ["Tokelau", "NZD"],
  ["Tonga", "TOP"],
  ["Trinidad and Tobago", "TTD"],
  ["Tunisia", "TND"],
  ["Turkey", "TRY"],
  ["Turkmenistan", "TMT"],
  ["Tuvalu", "AUD"],
  ["Uganda", "UGX"],
  ["Ukraine", "UAH"],
  ["United Arab Emirates", "AED"],
  ["United Kingdom", "GBP"],
  ["United States", "USD"],
  ["Uruguay", "UYU"],
  ["Uzbekistan", "UZS"],
  ["Vanuatu", "VUV"],
  ["Venezuela", "VEF"],
  ["Vietnam", "VND"],
  ["Wales", "GBP"],
  ["Western Sahara", "MAD"],
  ["Yemen", "YER"],
  ["Zambia", "ZMW"],
  ["Zimbabwe", "ZWD"],
];

export const imgUrl =
  "https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data";
export const s3Url =
  "https://s3.us-east-2.amazonaws.com/simplyfleet.app/image_data";

export const providerNameMapping = {
  1: "Samsara",
  2: "Geotab",
  3: "Streamline",
  4: "Verizon Connect",
};
