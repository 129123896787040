import React, { useEffect, useRef, useState, useContext } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  inMemoryPersistence,
  updateProfile,
} from "firebase/auth";
import FormHeader from "../../shared/components/form/FormHeader";
import { Card, CardBody } from "@/shared/components/Card";
import { useHistory, useLocation } from "react-router";
import { Nav, Tab } from "react-bootstrap";
import styled from "styled-components";

import { NavItem, NavLink, TabsWrap } from "@/shared/components/Tabs";
import { FormContainer } from "../../shared/components/form/FormElements";
import UserDetailsForm from "./UserDetailsForm";
import {
  colorBlue,
  colorLightGray,
  colorStatusActive,
  disabledBgColor,
  scrollbarStyles,
  sidenavColor,
} from "../../utils/palette";
import DocumentsForm from "./DocumentsForm";

import getUnixTime from "date-fns/getUnixTime";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "../../shared/components/TableElements";
import { uploadImgToS3 } from "../Issues/IssuesForm";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import { post, postApi, putApi } from "../../api/getUser";

import InfoPopup from "../../shared/components/InfoPopup";
import { DeletedContext } from "../../containers/App/Router/WrappedRoutes";
import { getDisplayDate, isObjEmpty } from "../../shared/helpers";

import { auth, firebaseConfig } from "../../api/firebase";

import VehiclesAssignedForm from "./VehiclesAssignedForm";
import { updateUser } from "../../features/user/userSlice";
import { useAuth } from "../../contexts/AuthContext";
import { Desc } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";

const UserManagementForm = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const { orgName } = useSelector((state) => state.user);

  const routeData = location?.state?.data;
  const [rowData, setRowData] = useState(routeData?.rowData);
  const screen = location?.state?.screen;
  const type = location?.state?.type;
  const mode = location?.state?.mode;

  const { setReload, setDummyRecordsPopup } = useAuth();

  const { orgId, userId } = useSelector((state) => state.user);

  //let userInfo = useRef({});
  let licenseInfo = useRef({});

  const methods = useForm();
  const { errors } = methods.formState;
  const { watch } = methods;
  //console.log(location?.state);

  //hook form variables

  //User Details Data
  const name = watch("name");
  const email = watch("email");
  const phone = watch("phone");
  const address = watch("address");
  const role = watch("role");
  const manager = watch("manager");
  const joiningDate = watch("joiningDate");
  const rate = watch("rate");
  const startTime = watch("startTime");
  const endTime = watch("endTime");
  const comments = watch("comments");
  const status = watch("status");

  //LicenseForm Data
  const licenseNo = watch("licenseNo");
  const licenseExpiry = watch("licenseExpiry");
  const licenseAddress = watch("licenseAddress");
  //const licenseDocFile = watch("licenseDocFile");
  const [licenseDocFile, setLicenseDocFile] = useState(null);

  const [userPhoto, setUserPhoto] = useState("");
  const [schedule, setSchedule] = useState([]);

  const [documents, setDocuments] = useState([]);
  const [docsIncomplete, setDocsIncomplete] = useState(false);

  //State to check if any document has been changed to updated last updated date
  const [docUpdated, setDocUpdated] = useState(false);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //For showing info popup during save
  const [addUserFailedData, setAddUserFailedData] = useState({
    message: "",
    icon: { className: "fi fi-rr-triangle-warning", color: "red" },
  });
  const [addUserFailed, setAddUserFailed] = useState(false);

  //For showing info popup when user is restored
  const [userRestoredData, setUserRestoredData] = useState({
    message: t("user_restored_msg"),
    icon: { className: "fi fi-rs-check-circle", color: colorStatusActive },
  });
  const [userRestored, setUserRestored] = useState(false);

  //States for add user popup
  const [showUserAddedPopup, setShowUserAddedPopup] = useState(false);
  const [userAdded, setUserAdded] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
  });

  //State for vehicles assigned
  let vehiclesList = useSelector((state) => state.vehicles.list);
  vehiclesList = vehiclesList
    .slice(1, vehiclesList?.length)
    .map((v) => ({ ...v, checked: false }));
  const [vehiclesAssigned, setVehiclesAssigned] = useState([]);
  //State to check if vehicles assigned has been changed
  const [vehAssignedUpdated, setVehAssignedUpdated] = useState(false);

  const [activeKey, setActiveKey] = useState("1");

  //State to show initial thak you for registering popup
  const [showInitialRegPopup, setShowInitialRegPopup] = useState(false);

  //State to show initial thak you for registering popup
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);

  const handleReqPopupClose = () => setShowThankYouPopup(true);

  //For first time user creation, rowData is retrieved from usersList
  const allUsers = useSelector((state) => state.users.list);
  useEffect(() => {
    //const mode = location?.state?.mode;
    //console.log(mode, allUsers, location?.state?.data?.userId);
    if (mode === "from-signup") {
      setShowInitialRegPopup(true);
      setDummyRecordsPopup({ show: false, signUp: true });
      const userId = location?.state?.data?.userId;
      let temp = allUsers?.find((u) => u.user_id === userId);
      console.log("temp", temp);
      //temp?.name = '';
      setRowData({ ...temp, name: "" });
    }
  }, [allUsers]);

  const checkDocsIncomplete = () => {
    for (const doc of documents) {
      if (!doc.docName || !doc.image) {
        //console.log(!doc.docName || !doc.image);
        return true;
      }
    }
    return false;
  };

  const onUserRestoredClose = () => {
    setUserRestored(false);
    history.push("/users", { fromCancel: false });
  };

  //console.log(errors);
  const onSubmit = async (data) => {
    try {
      setDocuments((prev) => {
        let temp = [...prev];
        for (let doc of temp) {
          if (!doc.docName || !doc.image) {
            doc.isError = true;
          } else {
            doc.isError = false;
          }
        }
        return temp;
      });
      const docsIncomplete = checkDocsIncomplete();
      if (docsIncomplete) {
        //console.log("incomplete");
        setDocsIncomplete(true);
        return;
      }

      const currentUserId =
        type === "edit" ? rowData?.user_id : `${orgId}_${Date.now()}`;

      const licenseData = JSON.stringify({
        license: [licenseNo, licenseExpiry, licenseAddress],
      });

      let docs = { All_Documents: [] };

      documents?.forEach((d) => {
        let items = [];
        if (d.imgUpdated) {
          items.push({
            title: "Updated date",
            author: getDisplayDate(getUnixTime(new Date())),
          });
        }
        d.fields.forEach((f) => {
          items.push({
            title: f.title ? f.title : "",
            author: f.author ? f.author : "",
          });
        });

        let temp = {};
        temp[d.docName] = {
          items: items,
          image: d.image.file ? [d.image.extension] : d.image.type,
          visible: d.isVisible,
          //image_format: d.image.imgFormat,
        };
        docs.All_Documents.push(temp);
      });

      const startingTime = startTime ? startTime + ":00" : "00:00:00";
      const endingTime = endTime ? endTime + ":00" : "00:00:00";

      const apiData = {
        sync_version: "v2",
        user_id: currentUserId,
        user_email: email ? email?.toLocaleLowerCase() : "",
        fname: name ? name : "",
        user_phone: phone ? phone : "",
        user_address: address ? address : "",
        role_id: role.value,
        manager_name:
          role.value === 1 || role.value === 4 ? "N/A" : manager.label,
        manager_id: role.value === 1 || role.value === 4 ? -1 : manager.user_id,
        comments: comments ? comments : "",
        user_img: userPhoto ? userPhoto.imgName : "",
        license_img: licenseDocFile ? licenseDocFile.imgName : "",
        license_info: licenseData,
        dot_card_img: "",
        dot_card_info: "",
        action: type === "edit" ? 2 : 1,
        user_active: status ? "1" : "0",
        source: "web",
        operation: type === "edit" ? "update" : "create",
        jdate: joiningDate ? getUnixTime(joiningDate) : "",
        document_object: documents?.length > 0 ? JSON.stringify(docs) : "",
        working_days: schedule?.length > 0 ? schedule.join(":") : "",
        hourly_rate: rate ? rate : "",
        starting_time: startingTime,
        ending_time: endingTime,
      };

      console.log(apiData);

      const vehiclesAssignedIds = vehiclesAssigned.map((v) => v.vehicleId);
      const vehApiData = {
        querystring: "operator_vehicle_update",
        operator_id: currentUserId,
        vehicle_id: vehiclesAssignedIds,
      };

      setSaveBtnLoading(true);

      //Email validation check
      if (type !== "edit") {
        const checkUserEmailApi = {
          user_email_to_check: email ? email?.toLocaleLowerCase() : "",
          querystring: "check_user_email_exist",
        };

        const checkUserEmailRes = await post("commonNew", checkUserEmailApi);

        if (!checkUserEmailRes.success) {
          setAddUserFailedData((prev) => ({
            ...prev,
            message: t("form_err_msg"),
          }));
          setSaveBtnLoading(false);
          setAddUserFailed(true);
          return;
        }
        if (checkUserEmailRes.success) {
          const { data } = checkUserEmailRes;

          if (data !== 0) {
            if (data === 1) {
              setAddUserFailedData((prev) => ({
                ...prev,
                message: t("user_limit_reached_err"),
              }));
            } else if (data === 2) {
              setAddUserFailedData((prev) => ({
                ...prev,
                message: t("allready_in_org_err"),
              }));
            } else if (data === 3) {
              setAddUserFailedData((prev) => ({
                ...prev,
                message: t("otherdb_err"),
              }));
            } else {
              setAddUserFailedData((prev) => ({
                ...prev,
                message: t("form_err_msg"),
              }));
            }
            setSaveBtnLoading(false);
            setAddUserFailed(true);
            return;
          }
        }
      }

      const response = await post("user", apiData);
      if (vehAssignedUpdated) {
        const vehResponse = await postApi(vehApiData, "commonNew");
        console.log("vehResponse", vehResponse);
      }

      if (!response?.success) {
        const { code } = response;
        if (code == 3) {
          setAddUserFailedData((prev) => ({
            ...prev,
            message: t("otherdb_err"),
          }));
        } else if (code == 2) {
          setAddUserFailedData((prev) => ({
            ...prev,
            message: t("allready_in_org_err"),
          }));
        } else if (code == 1) {
          setAddUserFailedData((prev) => ({
            ...prev,
            message: t("user_limit_reached_err"),
          }));
        } else {
          setAddUserFailedData((prev) => ({
            ...prev,
            message: t("form_err_msg"),
          }));
        }
        setSaveBtnLoading(false);
        setAddUserFailed(true);
        return;
      }

      if (response?.success) {
        console.log("currentUserId", currentUserId);
        console.log("userId", userId);
        if (currentUserId === userId) {
          //Get User Profile

          const apiData = {
            querystring: "get_user_profile",
          };

          const response = await postApi(apiData, "commonNew");
          const userData = response.user_data[0];
          dispatch(updateUser(userData));
        }
        const snackbarMsg =
          type === "edit"
            ? t("record_edited_msg", { recordName: t("user") })
            : t("record_added_msg", { recordName: t("user") });
        let imagesToS3 = [];
        if (userPhoto && userPhoto.file) {
          imagesToS3.push({
            file: userPhoto.file,
            key: `image_data/org_${orgId}/user_${currentUserId}/profile/${userPhoto.imgName}`,
          });
        }

        if (licenseDocFile && licenseDocFile.file) {
          imagesToS3.push({
            file: licenseDocFile.file,
            key: `image_data/org_${orgId}/user_${currentUserId}/license/${licenseDocFile.imgName}`,
          });
        }

        documents?.forEach((doc) => {
          if (doc.image.file) {
            imagesToS3.push({
              file: doc.image.file,
              key: `image_data/org_${orgId}/user_${currentUserId}/Documents/${doc.docName}.${doc.image.extension}`,
            });
          }
        });

        if (imagesToS3?.length > 0) {
          // const docsUploaded = await uploadDocsToS3(imagesToS3);
          // if (docsUploaded) {
          //   if (type !== "edit") {
          //     let userName = "";
          //     if (name.includes(" ")) {
          //       userName = name.split(" ")[0];
          //       console.log("userName");
          //     } else {
          //       console.log("userName");
          //       userName = name;
          //     }
          //     userName = userName.toLocaleLowerCase();
          //     let password = userName + "@simplyfleet";
          //     const tempApp = initializeApp(firebaseConfig, currentUserId);
          //     const tempAuth = getAuth(tempApp);
          //     tempAuth.setPersistence(inMemoryPersistence);

          //     const res = await createUserWithEmailAndPassword(
          //       tempAuth,
          //       email,
          //       password
          //     );
          //     const user = res?.user;
          //     console.log(user);
          //     if (user) {
          //       updateProfile(tempAuth?.currentUser, {
          //         displayName: userName,
          //       });
          //       setSaveBtnLoading(false);
          //       setUserData({
          //         name: userName,
          //         email: email,
          //         password: password,
          //       });
          //       setShowUserAddedPopup(true);
          //     }
          //   } else {
          //     //toggleReload();
          //     setReload("users");
          //     dispatch(addMsg(snackbarMsg));
          //     setSaveBtnLoading(false);
          //     if (mode === "from-signup") {
          //       history.push("/users", { mode: "from-signup" });
          //     } else {
          //       history.push("/users");
          //     }
          //   }
          // }

          const statusArr = [];
          imagesToS3.forEach(async (img, i) => {
            if (img.file) {
              const status = await uploadImgToS3(img);
              statusArr.push(status);
              if (statusArr.length === imagesToS3.length) {
                //console.log(statusArr);
                const imgUploadSuccess = statusArr.every(
                  (status) => status === 200
                );
                //console.log(imgUploadSuccess);
                if (imgUploadSuccess) {
                  if (type !== "edit") {
                    let userName = name;
                    if (name.includes(" ")) {
                      userName = name.split(" ")[0];
                    }
                    let userNameForPW = name
                      .replace(/\s/g, "")
                      .toLocaleLowerCase();
                    let password = userNameForPW + "@simplyfleet";
                    const tempApp = initializeApp(
                      firebaseConfig,
                      currentUserId
                    );
                    const tempAuth = getAuth(tempApp);
                    tempAuth.setPersistence(inMemoryPersistence);

                    //Send user email
                    const userEmailApiData = {
                      user_email: email?.toLocaleLowerCase(),
                      email: auth.currentUser.email,
                      role_type: role?.value,
                      querystring: "send_operator_email",
                      name: userNameForPW,
                      org_name_string: orgName,
                    };
                    await postApi(userEmailApiData, "commonNew");

                    const res = await createUserWithEmailAndPassword(
                      tempAuth,
                      email.toLocaleLowerCase(),
                      password
                    );
                    const user = res?.user;
                    if (user) {
                      updateProfile(tempAuth?.currentUser, {
                        displayName: userNameForPW,
                      });
                      setSaveBtnLoading(false);
                      setUserData({
                        name: userName,
                        email: email.toLocaleLowerCase(),
                        password: password,
                      });
                      setShowUserAddedPopup(true);
                    }
                  } else {
                    //setReload("users");
                    dispatch(addMsg(snackbarMsg));
                    setSaveBtnLoading(false);
                    if (mode === "from-signup") {
                      history.push("/users", {
                        mode: "from-signup",
                        fromCancel: false,
                      });
                    } else {
                      history.push("/users", { fromCancel: false });
                    }
                  }
                }
              }
            }
          });
        } else {
          if (type !== "edit") {
            let userName = name;
            if (name.includes(" ")) {
              userName = name.split(" ")[0];
            }
            //userName = userName.toLocaleLowerCase();
            let userNameForPW = name.replace(/\s/g, "").toLocaleLowerCase();
            let password = userNameForPW + "@simplyfleet";
            // try {
            const tempApp = initializeApp(firebaseConfig, currentUserId);
            const tempAuth = getAuth(tempApp);
            tempAuth.setPersistence(inMemoryPersistence);

            //Send user email
            const userEmailApiData = {
              user_email: email?.toLocaleLowerCase(),
              email: auth.currentUser.email,
              role_type: role?.value,
              querystring: "send_operator_email",
              name: userNameForPW,
              org_name_string: orgName,
            };
            await postApi(userEmailApiData, "commonNew");

            const res = await createUserWithEmailAndPassword(
              tempAuth,
              email.toLocaleLowerCase(),
              password
            );
            const user = res?.user;
            console.log(user);
            if (user) {
              updateProfile(tempAuth?.currentUser, {
                displayName: userNameForPW,
              });
              setSaveBtnLoading(false);
              setUserData({
                name: userName,
                email: email.toLocaleLowerCase(),
                password: password,
              });
              setShowUserAddedPopup(true);
            }
          } else {
            //setReload("users");
            dispatch(addMsg(snackbarMsg));
            setSaveBtnLoading(false);
            if (mode === "from-signup") {
              history.push("/users", {
                mode: "from-signup",
                fromCancel: false,
              });
            } else {
              history.push("/users", { fromCancel: false });
            }
          }
        }
      }
    } catch (e) {
      if (e?.code === "auth/email-already-in-use") {
        setUserRestored(true);
      } else {
        setAddUserFailedData((prev) => ({
          ...prev,
          message: t("form_err_msg"),
        }));
        setAddUserFailed(true);
      }
      setSaveBtnLoading(false);
      return;
    }
    // })();
  };

  return (
    <FormProvider {...methods}>
      {/* {showInitialRegPopup && (
        <InfoPopup
          //message={t("thank_you_for_reg_msg")}
          message={<Trans i18nKey="thank_you_for_reg_msg" t={t}></Trans>}
          showPopup={showInitialRegPopup}
          setShowPopup={setShowInitialRegPopup}
          icon={{ className: "fi fi-rr-user", color: colorBlue }}
          actionIcon={{
            label: t("okay"),
            handler: () => setShowInitialRegPopup(false),
          }}
        />
      )} */}
      {showThankYouPopup && (
        <InfoPopup
          //message={t("thank_you_for_reg_msg")}
          message={<Trans i18nKey="thank_you_for_reg_msg" t={t}></Trans>}
          showPopup={showThankYouPopup}
          setShowPopup={setShowThankYouPopup}
          icon={{ className: "fi fi-rr-user", color: colorBlue }}
          actionIcon={{
            label: t("okay"),
            handler: () => setShowThankYouPopup(false),
          }}
        />
      )}
      {showInitialRegPopup && (
        <RequirementsPopup
          showPopup={showInitialRegPopup}
          setShowPopup={setShowInitialRegPopup}
          setShowThankYouPopup={setShowThankYouPopup}
        />
      )}
      {showUserAddedPopup && (
        <UserAddedPopup
          showPopup={showUserAddedPopup}
          setShowPopup={setShowUserAddedPopup}
          userAdded={userAdded}
          setUserAdded={setUserAdded}
          userData={userData}
        />
      )}
      {docsIncomplete && (
        <InfoPopup
          message={t("doc_tab_incomplete_err_msg")}
          icon={{ className: "fi fi-rr-triangle-warning", color: "red" }}
          showPopup={docsIncomplete}
          setShowPopup={setDocsIncomplete}
        />
      )}
      {addUserFailed && (
        <InfoPopup
          message={addUserFailedData.message}
          icon={addUserFailedData.icon}
          showPopup={addUserFailed}
          setShowPopup={setAddUserFailed}
        />
      )}
      {userRestored && (
        <InfoPopup
          message={userRestoredData.message}
          icon={userRestoredData.icon}
          showPopup={userRestored}
          setShowPopup={setUserRestored}
          onClose={onUserRestoredClose}
          actionIcon={{ label: t("ok"), handler: onUserRestoredClose }}
        />
      )}
      <FormContainer onSubmit={methods.handleSubmit(onSubmit)}>
        <FormHeader
          handleCancel={() => history.push("/users", { fromCancel: true })}
          //disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          title={type === "edit" ? t("edit_user") : t("add_user")}
          //handleCancel={handleCancel}
        />
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody
                  style={{
                    padding: 0,
                    //height: `${window.innerHeight - 150}px`,
                    //overflow: "auto",
                  }}
                >
                  <TabsWrap>
                    <Tabs
                      rowData={rowData}
                      type={type}
                      //userInfo={userInfo}
                      userPhoto={userPhoto}
                      setUserPhoto={setUserPhoto}
                      schedule={schedule}
                      setSchedule={setSchedule}
                      licenseInfo={licenseInfo}
                      documents={documents}
                      setDocuments={setDocuments}
                      docUpdated={docUpdated}
                      setDocUpdated={setDocUpdated}
                      errors={errors}
                      vehiclesAssigned={vehiclesAssigned}
                      setVehiclesAssigned={setVehiclesAssigned}
                      vehAssignedUpdated={vehAssignedUpdated}
                      setVehAssignedUpdated={setVehAssignedUpdated}
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      licenseDocFile={licenseDocFile}
                      setLicenseDocFile={setLicenseDocFile}
                    />
                  </TabsWrap>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </FormContainer>
    </FormProvider>
  );
};

export default UserManagementForm;

const Tabs = ({
  rowData,
  type,
  documents,
  setDocuments,
  licenseInfo,
  docUpdated,
  setDocUpdated,
  errors,
  vehiclesAssigned,
  setVehiclesAssigned,
  vehAssignedUpdated,
  setVehAssignedUpdated,
  activeKey,
  setActiveKey,
  userPhoto,
  setUserPhoto,
  schedule,
  setSchedule,
  licenseDocFile,
  setLicenseDocFile,
}) => {
  const { t } = useTranslation("common");

  return (
    <Tab.Container activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
      <TabsWrap>
        <Nav className="nav-tabs">
          <NavItem
            id="user-details-tab"
            $active={activeKey == "1"}
            // style={{
            //   background: activeKey !== "1" ? "aliceblue" : "none",
            // }}
          >
            <NavLink eventKey="1" name="user-details-anchor">
              {t("user_details")}{" "}
              {!isObjEmpty(errors) && (
                <i
                  className="fi fi-rr-triangle-warning"
                  style={{
                    color: "red",
                    position: "relative",
                    top: "2px",
                  }}
                ></i>
              )}
            </NavLink>
          </NavItem>
          <NavItem
            id="documents-tab"
            $active={activeKey == "2"}
            // style={{
            //   background: activeKey !== "2" ? "aliceblue" : "none",
            // }}
          >
            <NavLink eventKey="2" name="documents-anchor">
              {documents.length > 0
                ? t("count_documents_added", {
                    count: documents?.length,
                  })
                : t("documents")}
            </NavLink>
          </NavItem>
          <NavItem
            id="vehicles-assigned-tab"
            $active={activeKey == "3"}
            // style={{
            //   background: activeKey !== "3" ? "aliceblue" : "none",
            // }}
          >
            <NavLink eventKey="3" name="vehicles-assigned-anchor">
              {vehiclesAssigned?.length > 0
                ? t("count_vehicles_assigned", {
                    count: vehiclesAssigned?.length,
                  })
                : t("vehicle_assignment")}
            </NavLink>
          </NavItem>
        </Nav>
        <Tab.Content>
          <TabPane
            eventKey="1"
            style={{
              height: `${window.innerHeight - 200}px`,
              overflow: "auto",
            }}
          >
            <UserDetailsForm
              rowData={rowData}
              type={type}
              userPhoto={userPhoto}
              setUserPhoto={setUserPhoto}
              schedule={schedule}
              setSchedule={setSchedule}
              vehiclesAssigned={vehiclesAssigned}
              setVehiclesAssigned={setVehiclesAssigned}
              vehAssignedUpdated={vehAssignedUpdated}
              setVehAssignedUpdated={setVehAssignedUpdated}
            />
          </TabPane>
          <TabPane
            eventKey="2"
            // style={{
            //   height: `${window.innerHeight - 198}px`,
            //   overflow: "auto",
            // }}
          >
            <DocumentsForm
              rowData={rowData}
              type={type}
              documents={documents}
              setDocuments={setDocuments}
              licenseInfo={licenseInfo}
              licenseDocFile={licenseDocFile}
              setLicenseDocFile={setLicenseDocFile}
              docUpdated={docUpdated}
              setDocUpdated={setDocUpdated}
            />
          </TabPane>
          <TabPane eventKey="3">
            <VehiclesAssignedForm
              vehiclesAssigned={vehiclesAssigned}
              setVehiclesAssigned={setVehiclesAssigned}
              vehAssignedUpdated={vehAssignedUpdated}
              setVehAssignedUpdated={setVehAssignedUpdated}
              type={type}
              rowData={rowData}
            />
          </TabPane>
        </Tab.Content>
      </TabsWrap>
    </Tab.Container>
  );
};

export const TabPane = styled(Tab.Pane)`
  ${scrollbarStyles};
  //height: ${window.innerHeight - 192}px;
  //padding: 1rem;
`;

export const UserAddedPopup = ({
  showPopup,
  setShowPopup,
  userAdded,
  setUserAdded,
  userData,
}) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const { setReload } = useAuth();

  const handleOkay = () => {
    //setReload("users");
    dispatch(addMsg(t("record_added_msg", { recordName: t("user") })));
    history.push("/users", { fromCancel: false });
    setShowPopup(false);
  };
  return (
    <Modal
      show={showPopup}
      //onHide={() => setShowPopup(!showPopup)}
      centered
      size="md"
    >
      <Modal.Header>
        {/* <i
          className="fi fi-br-check-circle"
          style={{ color: colorGreenStatus, width: 24, height: 24 }}
        ></i> */}
        <p style={{ fontSize: "18px", fontWeight: 500 }}>{t("user_added")}</p>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">
        <div className="align-self-start">
          <p>{`${t("user_added_msg_1_new", {
            userName: userData.name,
          })}`}</p>
          <br />
          <p>
            {t("email")}:{" "}
            <span style={{ fontWeight: 500 }}>{userData.email}</span>
          </p>
          <p>
            {t("password")}:{" "}
            <span style={{ fontWeight: 500 }}>{userData.password}</span>
          </p>
          <br />
          <p>{`${t("user_added_msg_2_new", {
            userName: userData.name,
          })}`}</p>
        </div>
        <AddFormButton onClick={handleOkay}>{t("okay")}</AddFormButton>
      </Modal.Body>
    </Modal>
  );
};

export const RequirementsPopup = ({
  showPopup,
  setShowPopup,
  setShowThankYouPopup,
}) => {
  const { t } = useTranslation("common");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const requirementsArr = [
    {
      key: "preventiveMaintenance",
      label: t("preventive_maintenance"),
      iconClassName: "fi fi-rs-shipping-timed",
    },
    {
      key: "vehicleInspections",
      label: t("vehicle_inspections"),
      iconClassName: "fi fi-rr-clipboard-list-check",
    },
    {
      key: "fuelMgmt",
      label: t("fuel_mgmt"),
      iconClassName: "fi fi-rr-gas-pump-alt",
    },
    {
      key: "assetDriverMgmt",
      label: t("asset_driver_mgmt"),
      iconClassName: "fi fi-rr-user",
    },
    {
      key: "incidentReporting",
      label: t("incident_reporting"),
      iconClassName: "fi fi-rr-engine-warning",
    },
    {
      key: "fleetExpenseMgmt",
      label: t("fleet_expense_mgmt"),
      iconClassName: "fi fi-sr-search-dollar",
    },
    {
      key: "tripLogging",
      label: t("trip_logging"),
      iconClassName: "fi fi-rr-road",
    },
    {
      key: "workOrders",
      label: t("work_orders"),
      iconClassName: "fi fi-rr-note",
    },
    {
      key: "inventoryMgmt",
      label: t("inventory_mgmt"),
      iconClassName: "fi fi-rr-supplier-alt",
    },
    {
      key: "gpsLiveTracking",
      label: t("gps_live_tracking"),
      iconClassName: "fi fi-rr-map-marker",
      comingSoon: true,
    },
  ];

  const reqObj = requirementsArr.reduce(
    (acc, curr) => ({ ...acc, [curr.key]: false }),
    {}
  );

  const [requiremntsData, setRequirementsData] = useState(reqObj);

  const handleClick = (key) => {
    setRequirementsData((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleSubmit = async () => {
    setSubmitBtnLoading(true);
    const {
      preventiveMaintenance,
      vehicleInspections,
      fuelMgmt,
      assetDriverMgmt,
      incidentReporting,
      fleetExpenseMgmt,
      tripLogging,
      workOrders,
      gpsLiveTracking,
      inventoryMgmt,
    } = requiremntsData;
    const apiData = {
      querystring: "set_org_requirements",
      preventive_maintenance: preventiveMaintenance ? 1 : 0,
      vehicle_inspections: vehicleInspections ? 1 : 0,
      fuel_management: fuelMgmt ? 1 : 0,
      asset_management: assetDriverMgmt ? 1 : 0,
      incident_reporting: incidentReporting ? 1 : 0,
      exp_management: fleetExpenseMgmt ? 1 : 0,
      trip_logging: tripLogging ? 1 : 0,
      work_orders: workOrders ? 1 : 0,
      live_tracking: gpsLiveTracking ? 1 : 0,
      inventory_management: inventoryMgmt ? 1 : 0,
    };
    await postApi(apiData, "commonNew");
    setSubmitBtnLoading(false);
    setShowPopup(false);
    setShowThankYouPopup(true);
  };

  const RequirementInput = ({ requirement, checked, handleClick }) => {
    return (
      <RequirementContainer
        $checked={checked}
        onClick={handleClick}
        //$disabled={requirement?.comingSoon}
      >
        <i className={requirement.iconClassName} />
        <span
        // style={{
        //   maxWidth: "80px",
        //   display: "-webkit-box",
        //   WebkitLineClamp: 2,
        //   WebkitBoxOrient: "vertical",
        //   overflow: "hidden",
        //   maxHeight: "8em",
        //   textOverflow: "ellipsis",
        //   textAlign: "center",
        //   color: sidenavColor,
        //   fontSize: "12px",
        // }}
        >
          {requirement.label}
        </span>
        {requirement?.comingSoon && (
          <span style={{ fontSize: 10 }}>{`(${t("coming_soon")})`}</span>
        )}
      </RequirementContainer>
    );
  };

  return (
    <Modal
      show={showPopup}
      //onHide={() => setShowPopup(!showPopup)}
      centered
      size="lg"
    >
      <Modal.Header>
        <div>
          <h5>{t("what_are_your_goals_with_sf")}</h5>
          <Desc>{`(${t("select_all_that_apply")})`}</Desc>
        </div>
        <div
          className="d-flex justify-content-end"
          //style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer
            onClick={() => {
              setShowPopup(false);
              setShowThankYouPopup(true);
            }}
          >
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          {/* <Row className="mb-3">
            <Col>
              <div>
                <Desc>{`(${t("select_all_that_apply")})`}</Desc>
              </div>
            </Col>
          </Row> */}

          <Row>
            {requirementsArr.slice(0, 5).map((req) => (
              <Col className="d-flex justify-content-center" key={req.key}>
                <RequirementInput
                  //key={req.key}
                  requirement={req}
                  handleClick={() => handleClick(req.key)}
                  checked={requiremntsData[req.key]}
                />
              </Col>
            ))}
          </Row>
          <br />
          <Row>
            {requirementsArr.slice(5).map((req) => (
              <Col className="d-flex justify-content-center" key={req.key}>
                <RequirementInput
                  //key={req.key}
                  requirement={req}
                  handleClick={() => handleClick(req.key)}
                  checked={requiremntsData[req.key]}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className="d-flex justify-content-between"
          style={{ width: "-webkit-fill-available" }}
        >
          <FilterButton
            type="button"
            id="skip-btn"
            onClick={() => {
              setShowPopup(false);
              setShowThankYouPopup(true);
            }}
          >
            {t("skip")}
          </FilterButton>
          <AddFormButton
            onClick={handleSubmit}
            type="button"
            id="submit-btn"
            disabled={submitBtnLoading}
          >
            {submitBtnLoading ? <CustomLoader /> : t("submit")}
          </AddFormButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

//styles

const RequirementContainer = styled.div`
  width: 128px;
  height: 128px;
  border: 1px solid ${sidenavColor};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //gap: 1rem;
  padding: 1.25rem;
  cursor: pointer;

  //${(p) => !p.$disabled && `cursor: pointer;`}
  //${(p) => p.$disabled && `background: ${disabledBgColor};`}

  ${(p) =>
    p.$checked &&
    //!p.$disabled &&
    ` //border: 2px solid ${sidenavColor};
      background: #ddeefd; 
      `}

  ${(p) =>
    !p.$checked &&
    //!p.$disabled &&
    ` &:hover {
      background: ${colorLightGray};
    }
`}

  span {
    text-align: center;
    color: ${sidenavColor};
    font-size: 12px;
  }

  i {
    font-size: 32px;
    color: ${sidenavColor};
  }
`;
