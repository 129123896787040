import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export const getImportData = (screenName) => {
  const pathName = screenName ? screenName : useLocation().pathname;
  const { t } = useTranslation("common");

  const vehicleNameValues = [
    "vehicle name",
    "vehicle",
    "veh_id",
    "veh_name",
    //"Vehicle Name",
    //"Vehicle",
  ];
  const vinValues = [
    "vin",

    //"Vin",
    //"VIN"
  ];
  const licenseValues = [
    "license",
    "license no",
    "license_no",
    "license number",

    //"License",
    //"License No",
    //"License Number",
    //"license",
    //"License number",
  ];
  const commentsValues = [
    "note",
    "notes",
    "comments",
    "desc",
    "comment",
    //"Comments",
    //"Desc",
    //"Notes",
    //"Note",
    //"comment",
  ];

  const serviceTaskValues = [
    "service_task_name",
    "service task",
    "task name",
    "service task name",
    "task",
    //"Task Name",
    // "Service Task Name",
    // "Service task name",
    // "Task",
    // "Service Task",
    // "Service task",
    // "service Task",
    // "task name",
  ];
  const expenseTaskValues = [
    "expense_task_name",
    "expense task",
    "task",
    "task name",
    "expense task name",

    // "Task Name",
    // "Expense Task Name",
    // "Expense task name",
    // "Task",
    // "Expense Task",
    // "Expense task",
    // "expense Task",
    // "task name",
  ];

  const odometerValues = [
    "Odometer",
    "Odo",
    "odo",
    "odometer",
    "current_meter",
    "Mileage",
    "mileage",
  ];

  let options = [];
  let otherMandatoryFields = [];
  let queryString = "";
  let screenHeading = "";
  let sampleFileName = "";
  if (pathName?.includes("dailymileage")) {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        //checked: false,
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        //checked: false,
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        //checked: false,
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        //checked: false,
        possibleValues: [...vinValues],
      },
      // {
      //   value: "email",
      //   label: t("email"),
      //   //checked: false,
      //   possibleValues: ["Email", "email", "email_id", "email id"],
      // },
      {
        value: "timestamp",
        label: t("date_tv"),
        //checked: false,
        possibleValues: [
          "mileage date",
          "dates",
          "date",
          "transactiondate",
          "timestamp",

          //"Date",
          //"Dates",
          //"Mileage Date",
        ],
        type: "date",
      },
      {
        value: "starting_odo",
        label: t("starting_odo"),
        //checked: false,
        possibleValues: [
          "starting odo",
          "starting odometer",
          "starting_odo",
          //"odometer",
          //"current_meter",
          //"Starting Odo",
          //"starting odometer",
        ],
      },
      {
        value: "ending_odo",
        label: t("ending_odo"),
        //checked: false,
        possibleValues: [
          "ending_odo",
          "ending odo",
          "ending odometer",

          //"Ending Odo",
          //"Ending odometer",
          //"odometer",
          //"current_meter",
        ],
      },
      {
        value: "operator_name",
        label: t("entered_by"),
        //checked: false,
        possibleValues: ["entered_by", "entered by", "user", "operator"],
      },
      {
        value: "comments",
        label: t("notes_tv"),
        //checked: false,
        possibleValues: [...commentsValues],
      },
    ];

    otherMandatoryFields = [
      { value: "timestamp", label: t("date_tv") },
      { value: "starting_odo", label: t("starting_odo") },
      //{ value: "operator_name", label: t("operator") },
    ];

    queryString = "import_dailymileage";
    screenHeading = t("se_mileage");
    sampleFileName = "daily_mileage_sample";
  }

  if (pathName?.includes("servicetasks")) {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        //checked: false,
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        //checked: false,
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        //checked: false,
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        //checked: false,
        possibleValues: [...vinValues],
      },
      {
        value: "service_task_name",
        label: t("service_task_name"),
        //checked: false,
        possibleValues: [...serviceTaskValues],
      },
      {
        value: "comments",
        label: t("notes_tv"),
        //checked: false,
        possibleValues: [...commentsValues],
      },
      {
        value: "recurring",
        label: t("recurring_task"),
        //checked: false,
        possibleValues: [
          "recurring",
          "repeating",
          "recurring task",
          "repeating task",

          //"Recurring",
          //"Repeating",
          //"Recurring Task",
          //"Repeating Task",
        ],
      },
    ];
    otherMandatoryFields = [
      { value: "service_task_name", label: t("service_task_name") },
    ];

    queryString = "import_service_task";
    screenHeading = t("service_tasks");
    sampleFileName = "service_task_sample";
  }

  if (pathName?.includes("expensetasks")) {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        //checked: false,
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        //checked: false,
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        //checked: false,
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        //checked: false,
        possibleValues: [...vinValues],
      },
      {
        value: "expense_task_name",
        label: t("expense_task_name"),
        //checked: false,
        possibleValues: [...expenseTaskValues],
      },
      {
        value: "comments",
        label: t("notes_tv"),
        //checked: false,
        possibleValues: [...commentsValues],
      },
      {
        value: "recurring",
        label: t("recurring_task"),
        //checked: false,
        possibleValues: [
          "recurring",
          "repeating",
          "recurring task",
          "repeating task",

          // "Recurring",
          // "recurring",
          // "Repeating",
          // "Recurring Task",
          // "Repeating Task",
        ],
      },
    ];
    otherMandatoryFields = [
      { value: "expense_task_name", label: t("expense_task_name") },
    ];

    queryString = "import_expense_task";
    screenHeading = t("expense_tasks");
    sampleFileName = "expense_task_sample";
  }

  if (pathName?.includes("vehicles")) {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        //checked: false,
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        //checked: false,
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        //checked: false,
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        //checked: false,
        possibleValues: [...vinValues],
      },
      {
        value: "make",
        label: t("make"),
        //checked: false,
        possibleValues: ["make", "Make"],
      },
      {
        value: "model",
        label: t("model"),
        //checked: false,
        possibleValues: ["Model", "model"],
      },
      {
        value: "group_id",
        label: t("group"),
        //checked: false,
        possibleValues: ["Group", "group"],
      },
      {
        value: "veh_type",
        label: t("veh_type"),
        //checked: false,
        possibleValues: [
          "type",
          "vehicle type",

          //"Vehicle Type",
          //"Vehicle type",
          //"Type",
        ],
      },
      {
        value: "veh_active",
        label: `${t("vehicle")} ${"active"}`,
        //checked: false,
        possibleValues: [
          "active",
          "vehicle active",
          "veh_active",

          // "Vehicle Active",
          // "Vehicle active",
          // "Active",
        ],
      },
      {
        value: "year",
        label: `${t("year")}`,
        //checked: false,
        possibleValues: [
          "year",
          "vehicle year",
          //"Year",
          //"Vehicle Year",
        ],
      },
      {
        value: "starting_odo",
        label: t("starting_odo"),
        //checked: false,
        possibleValues: [
          "odometer",
          "current_meter",
          "transactiondate",
          "timestamp",
          "starting_odo",
          "starting odo",
          "starting odometer",
        ],
      },
      {
        value: "primary_meter",
        label: `${t("primary_meter")}`,
        //checked: false,
        possibleValues: [
          "primary_meter",
          "primary meter",
          "meter",

          //"Primary Meter",
          //"Primary meter",
          //"Meter",
        ],
      },
      {
        value: "purchase_price",
        label: `${t("purchase_price")}`,
        //checked: false,
        possibleValues: ["purchase_price", "purchase price"],
      },
      {
        value: "width",
        label: t("width"),
        //checked: false,
        possibleValues: ["Width", "width"],
      },
      {
        value: "length",
        label: t("length"),
        //checked: false,
        possibleValues: ["Length", "length"],
      },
      {
        value: "height",
        label: t("height"),
        //checked: false,
        possibleValues: ["Height", "height"],
      },
      {
        value: "weight",
        label: t("weight"),
        //checked: false,
        possibleValues: ["Weight", "weight"],
      },
      {
        value: "cargo_volume",
        label: t("cargo_volume"),
        //checked: false,
        possibleValues: ["cargo volume", "cargo", "cargo_volume"],
      },
      {
        value: "max_payload",
        label: t("max_payload"),
        //checked: false,
        possibleValues: [
          "maximum payload",
          "max payload",
          "max_payload",

          //"Max Payload",
          //"Maximum Payload",
        ],
      },
      {
        value: "front_tyre_info",
        label: t("front_tyre_info"),
        //checked: false,
        possibleValues: ["front_tyre_info", "front tyre info"],
      },
      {
        value: "front_tyre_psi",
        label: t("front_tyre_psi"),
        //checked: false,
        possibleValues: ["front_tyre_psi", "front tyre psi"],
      },
      {
        value: "rear_tyre_info",
        label: t("rear_tyre_info"),
        //checked: false,
        possibleValues: ["rear tyre info", "rear_tyre_info"],
      },
      {
        value: "rear_tyre_psi",
        label: t("rear_tyre_psi"),
        //checked: false,
        possibleValues: ["rear tyre psi", "rear_tyre_psi"],
      },
      {
        value: "fuel_type",
        label: t("fuel_type"),
        //checked: false,
        possibleValues: ["fuel type", "fuel_type"],
      },
      {
        value: "fuel_quality",
        label: t("fuel_quality"),
        //checked: false,
        possibleValues: ["fuel quality", "Fuel quality"],
      },
      {
        value: "oil",
        label: t("oil"),
        //checked: false,
        possibleValues: ["Oil", "oil"],
      },
      {
        value: "oil_capacity",
        label: t("oil_capacity"),
        //checked: false,
        possibleValues: ["oil capacity", "oil_capacity"],
      },
      {
        value: "fuel_tank_capacity",
        label: t("fuel_tank_capacity"),
        //checked: false,
        possibleValues: ["fuel tank capacity", "fuel_tank_capacity"],
      },
    ];

    otherMandatoryFields = [
      { value: "make", label: t("make") },
      { value: "model", label: t("model") },
      // { value: "veh_type", label: t("veh_type") },
    ];

    queryString = "import_vehicles";
    screenHeading = t("vehicles");
    sampleFileName = "vehicle_sample";
  }

  if (pathName?.includes("users")) {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        //checked: false,
        possibleValues: [],
      },
      {
        value: "name",
        label: t("name"),
        //checked: false,
        possibleValues: [
          "user",
          "user_name",
          "user name",
          "name",

          //"User Name",
          //"User name",
          //"User",
        ],
      },
      {
        value: "email",
        label: t("email"),
        //checked: false,
        possibleValues: [
          "email",
          "email id",
          "email address",

          //"Email",
          //"Email Id",
          //"Email Address",
          //"Email address",
          //"Email ID",
          //"email",
        ],
      },
      {
        value: "password",
        label: t("password"),
        //checked: false,
        possibleValues: ["Password", "PW", "password", "pw"],
      },
      {
        value: "role_id",
        label: t("role"),
        //checked: false,
        possibleValues: ["Role", "role"],
      },
      {
        value: "manager_id",
        label: t("manager"),
        //checked: false,
        possibleValues: ["Manager", "manager"],
      },
      {
        value: "phone",
        label: t("phone"),
        //checked: false,
        possibleValues: [
          "phone",
          "phone no",
          "phone number",
          "mobile",
          "mobile no",
          "mobile number",
          // "phone number",
          // "phone",
          // "Mobile",
          // "Mobile No",
          // "Mobile Number",
        ],
      },
      {
        value: "user_active",
        label: t("status"),
        //checked: false,
        possibleValues: ["status", "user status", "active", "user active"],
      },
      {
        value: "joining_date",
        label: t("joining_date"),
        type: "date",
        possibleValues: ["joining date", "joining_date"],
      },
      {
        value: "home_address",
        label: t("address"),
        //checked: false,
        possibleValues: ["home address", "address", "home_address"],
      },
      {
        value: "comments",
        label: t("notes_tv"),
        //checked: false,
        possibleValues: [...commentsValues],
      },
    ];

    otherMandatoryFields = [
      { value: "name", label: t("name") },
      { value: "email", label: t("email") },
      { value: "role_id", label: t("role") },
      { value: "password", label: t("password") },
      // { value: "veh_type", label: t("veh_type") },
    ];

    queryString = "import_user";
    screenHeading = t("users");
    sampleFileName = "users_sample";
  }

  if (pathName.includes("inspectiontasks")) {
    //task_id, org_id
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        //checked: false,
        possibleValues: [],
      },
      {
        value: "task_name",
        label: t("inspection_task_name"),
        //checked: false,
        possibleValues: [
          "task",
          "task name",
          "inspection_task_name",
          "inspection task name",
          "task_name",
          "inspection task",

          //"task Name",
          //"task name",
        ],
      },
      {
        value: "description",
        label: t("desc"),
        //checked: false,
        possibleValues: [
          "description",
          "descriptions",
          "desc",

          // "Description",
          // "Descriptions",
          // "Desc",
        ],
      },
      {
        value: "pass_required",
        label: t("pass_reqd"),
        //checked: false,
        possibleValues: [
          "pass",
          "pass_required",
          "pass required",
          "pass/fail",

          // "Pass",
          // "Pass Required",
        ],
      },
      {
        value: "value_required",
        label: t("value_reqd"),
        //checked: false,
        possibleValues: ["Value", "value", "value required", "value_required"],
      },
      {
        value: "image_required",
        label: t("img_reqd"),
        //checked: false,
        possibleValues: [
          "Image",
          "image",
          "img",
          "image required",
          "image_required",
        ],
      },
    ];

    otherMandatoryFields = [
      { value: "task_name", label: t("inspection_task_name") },
    ];

    queryString = "import_inspection_task";
    screenHeading = t("inspection_tasks");
    sampleFileName = "inspection_task_sample";
  }

  if (pathName?.includes("fillups")) {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        //checked: false,
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        //checked: false,
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        //checked: false,
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        //checked: false,
        possibleValues: [...vinValues],
      },
      {
        value: "fillup_date",
        label: t("date_tv"),
        //checked: false,
        possibleValues: [
          "Date",
          "date",
          "mileage date",
          "transactiondate",
          "timestamp",
          "fillup date",
          "fillup_date",
        ],
        type: "date",
      },
      {
        value: "odo",
        label: t("odometer"),
        //checked: false,
        possibleValues: [...odometerValues],
      },

      {
        value: "qty",
        label: t("qty_tv"),
        //checked: false,
        possibleValues: ["Quantity", "quantity", "Qty", "qty"],
      },
      {
        value: "price_per_qty",
        label: t("price_per_qty"),
        //checked: false,
        possibleValues: [
          "price_per_qty",
          "price",
          "price/qty",
          "price/quantity",
        ],
      },
      // {
      //   value: "qty_unit",
      //   label: t("qty_unit"),
      //   //checked: false,
      //   possibleValues: ["Quantity Unit", "Qty unit", "qty unit"],
      // },
      {
        value: "p_fill",
        label: t("pf_tv"),
        //checked: false,
        possibleValues: ["Partial Tank", "partial tank", "p_fill"],
      },
      {
        value: "m_fill",
        label: t("mf_tv"),
        //checked: false,
        possibleValues: ["missed prev fill-up", "m_fill"],
      },
      {
        value: "total_cost",
        label: t("tc_tv"),
        //checked: false,
        possibleValues: ["total cost", "total_cost", "total"],
      },
      {
        value: "fuel_type",
        label: t("fuel_type"),
        //checked: false,
        possibleValues: [
          "fuel type",
          "fuel_type",

          //"Fuel Type",
          //"fuel type",
          //"Fuel type",
        ],
      },
      {
        value: "octane",
        label: t("oct_tv"),
        //checked: false,
        possibleValues: ["Octane", "octane"],
      },
      {
        value: "fuel_brand",
        label: t("fb_tv"),
        //checked: false,
        possibleValues: [
          "fuel brand",
          "fuel_brand",

          //"Fuel Brand",
          //"Fuel brand",
          //"fuel Brand",
        ],
      },
      {
        value: "filling_station",
        label: t("fs_tv"),
        //checked: false,
        possibleValues: [
          "Filling Station",
          "filling_station",
          "filling station",
        ],
      },
      {
        value: "transaction_type",
        label: t("transaction_type"),
        //checked: false,
        possibleValues: [
          "transaction_type",
          "transaction type",

          //"Transaction Type",
          //"transaction Type",
        ],
      },
      // {
      //   value: "card_number",
      //   label: t("card_number"),
      //   possibleValues: ["card number", "card_number", "card", "card no"],
      // },
      {
        value: "filled_by",
        label: t("filled_by"),
        //checked: false,
        possibleValues: [
          "filled by",
          "operator",
          "operators",
          "user",
          "operator name",
        ],
      },
      {
        value: "comments",
        label: t("notes_tv"),
        //checked: false,
        possibleValues: [...commentsValues],
      },
    ];

    otherMandatoryFields = [
      { value: "odo", label: t("odometer") },
      { value: "qty", label: t("qty_tv") },
      { value: "fillup_date", label: t("date_tv") },
    ];

    queryString = "import_fillups";
    screenHeading = t("fillups");
    sampleFileName = "fillup_sample";
  }

  if (pathName?.includes("trips")) {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        possibleValues: [...vinValues],
      },
      {
        value: "trip_by",
        label: t("trip_by"),
        possibleValues: ["trip_by", "trip by", "driver"],
      },
      {
        value: "dep_date",
        label: t("dep_date_and_time"),
        possibleValues: [
          "dep_date",
          "departure",
          "departure date",
          "departure date and time",
          "dep date",
        ],
        type: "date",
      },
      {
        value: "arr_date",
        label: t("arr_date_and_time"),
        //checked: false,
        possibleValues: [
          "arr_date",
          "arrival",
          "arrival date",
          "arrival date and time",
          "arr date",
        ],
        type: "date",
      },
      {
        value: "dep_loc",
        label: t("dep_loc"),
        possibleValues: [
          "dep_loc",
          "departure location",
          "departure_location",
          "dep loc",
        ],
      },
      {
        value: "arr_loc",
        label: t("arr_loc"),
        possibleValues: [
          "arr_loc",
          "arrival location",
          "arrival_location",
          "arr loc",
        ],
      },
      {
        value: "dep_odo",
        label: t("starting_odo"),
        //checked: false,
        possibleValues: [
          "dep_odo",
          "Starting Odo",
          "starting odo",
          "starting_odo",
          "starting odometer",
          "dep odo",
          "departure odometer",
        ],
      },
      {
        value: "arr_odo",
        label: t("ending_odo"),
        //checked: false,
        possibleValues: [
          "arr_odo",
          "ending_odo",
          "ending odo",
          "ending odometer",
          "arr odo",
          "arrival odometer",
        ],
      },

      // {
      //   value: "avg_speed",
      //   label: t("average_speed"),
      //   //checked: false,
      //   possibleValues: ["avg_speed", "average speed", "avg speed"],
      // },
      {
        value: "comments",
        label: t("notes_tv"),
        //checked: false,
        possibleValues: [...commentsValues],
      },
    ];

    otherMandatoryFields = [
      //{ value: "trip_by", label: t("driver") },
      { value: "dep_date", label: t("dep_date_and_time") },
      { value: "arr_date", label: t("arr_date_and_time") },
      { value: "dep_odo", label: t("starting_odo") },
      { value: "arr_odo", label: t("ending_odo") },
    ];

    queryString = "import_trips";
    screenHeading = t("trips");
    sampleFileName = "trip_sample";
  }

  if (pathName.includes("maintenancereminders")) {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        possibleValues: [...vinValues],
      },
      {
        value: "service_task_name",
        label: t("service_task_name"),
        possibleValues: [...serviceTaskValues],
      },
      {
        value: "last_odo_of_service",
        label: t("last_service_odometer_sc"),
        possibleValues: [
          "last service odometer",
          "last odo of service",
          "last_odo_of_service",
          "last service odo",
        ],
      },
      {
        value: "last_date_of_service",
        label: t("last_service_date_sc"),
        possibleValues: [
          "last service date",
          "last date of service",
          "last_date_of_service",
          "last service date",
        ],
        type: "date",
      },
      {
        value: "due_odo",
        label: t("odometer_interval_sc"),
        possibleValues: ["odometer interval", "odo interval", "due_odo"],
      },
      {
        value: "due_days",
        label: t("days_interval_sc"),
        possibleValues: ["days Interval", "due_days", "day interval"],
      },
      {
        value: "due_on_odo",
        label: `${t("due_on_sc")} (${t("odometer_sm")})`,
        possibleValues: [
          "due_on_odo",
          "due on (odometer)",
          "due odo",
          //"Due odo",
        ],
      },
      {
        value: "due_on_date",
        label: `${t("due_on_sc")} (${t("date_sm")})`,
        possibleValues: ["due_on_date", "due on (days)", "due on"],
        type: "date",
      },
      {
        value: "emails",
        label: t("noti_recipients"),
        possibleValues: ["email", "emails", "email recipients"],
      },
      {
        value: "description",
        label: t("instructions"),
        possibleValues: [...commentsValues, "description", "instructions"],
      },
    ];

    otherMandatoryFields = [
      { value: "service_task_name", label: t("service_task_name") },
      { value: "due_odo", label: t("odometer_interval_sc") },
      { value: "due_days", label: t("days_interval_sc") },
    ];

    queryString = "import_reminders";
    screenHeading = t("maintenance_reminders");
    sampleFileName = "reminder_sample";
  }

  if (pathName.includes("renewals")) {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        possibleValues: [...vinValues],
      },
      {
        value: "service_task_name",
        label: t("expense_task_name"),
        possibleValues: [...expenseTaskValues],
      },
      {
        value: "last_odo_of_service",
        label: t("last_expense_odometer_sc"),
        possibleValues: [
          "last expense odo",
          "Last expense odometer",
          "last_odo_of_expense",
        ],
      },
      {
        value: "last_date_of_service",
        label: t("last_expense_date_sc"),
        possibleValues: [
          "Last expense date",
          "Last expense date",
          "last_date_of_expense",
        ],
        type: "date",
      },
      {
        value: "due_odo",
        label: t("odometer_interval_sc"),
        possibleValues: ["odometer interval", "odo interval", "due_odo"],
      },
      {
        value: "due_days",
        label: t("days_interval_sc"),
        possibleValues: ["days Interval", "due_days", "day interval"],
      },
      {
        value: "due_on_odo",
        label: `${t("due_on_sc")} (${t("odometer_sm")})`,
        possibleValues: ["due_on_odo", "due on (odometer)", "due odo"],
      },
      {
        value: "due_on_date",
        label: `${t("due_on_sc")} (${t("date_sm")})`,
        possibleValues: ["due_on_date", "due on (days)", "due on"],
        type: "date",
      },
      {
        value: "emails",
        label: t("noti_recipients"),
        possibleValues: ["email", "emails", "email recipients"],
      },
      {
        value: "description",
        label: t("instructions"),
        possibleValues: [...commentsValues, "description", "instructions"],
      },
    ];

    otherMandatoryFields = [
      { value: "service_task_name", label: t("expense_task_name") },
      { value: "due_odo", label: t("odometer_interval_sc") },
      { value: "due_days", label: t("days_interval_sc") },
    ];

    queryString = "import_reminders";
    screenHeading = t("renewals");
    sampleFileName = "renewals_sample";
  }

  if (pathName === "service") {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        possibleValues: [...vinValues],
      },
      {
        value: "odo",
        label: t("odometer"),
        possibleValues: [...odometerValues],
      },
      {
        value: "service_date",
        label: t("service_date"),
        possibleValues: [
          "service_date",
          "date",
          "dates",
          //"date",
          "service date",
          "timestamp",
        ],
        type: "date",
      },
      {
        value: "serviced_by",
        label: t("serviced_by"),
        possibleValues: ["serviced_by", "serviced by", "service by", "user"],
      },
      {
        value: "service_station",
        label: t("service_station"),
        possibleValues: [
          "service_station",
          "service station",
          //"Service Station",
          //"Service station",
        ],
      },
      {
        value: "service_task_name",
        label: t("service_task_name"),
        possibleValues: [...serviceTaskValues],
        isMultiple: true,
      },
      {
        value: "labor_cost",
        label: t("labor_cost"),
        possibleValues: [
          "labor_cost",
          "labor",
          "labor cost",

          //"Labor cost",
          //"Labor",
          //"labor Cost",
        ],
      },
      {
        value: "parts_cost",
        label: t("parts_cost"),
        possibleValues: [
          "parts_cost",
          "parts",
          "parts cost",

          //"Parts Cost",
          //"Parts",
          //"parts Cost",
        ],
      },
      {
        value: "total_cost",
        label: t("tc_tv"),
        possibleValues: ["total_cost", "total", "total cost"],
      },
      {
        value: "tax",
        label: t("tax"),
        possibleValues: ["Tax", "tax"],
      },
      {
        value: "discount",
        label: t("discount"),
        possibleValues: ["Discount", "discount"],
      },
      {
        value: "transaction_type",
        label: t("transaction_type"),
        possibleValues: ["transaction_type", "transaction type"],
      },
      // {
      //   value: "card_number",
      //   label: t("card_number"),
      //   possibleValues: ["card_number", "card number", "card", "card no"],
      // },
      {
        value: "comments",
        label: t("notes_tv"),
        //checked: false,
        possibleValues: [...commentsValues],
      },
    ];

    otherMandatoryFields = [
      { value: "expense_date", label: t("service_date") },
      { value: "service_task_name", label: t("service_task_name") },
      { value: "odo", label: t("odometer") },
    ];

    queryString = "import_services";
    screenHeading = t("services");
    sampleFileName = "service_log_sample";
  }

  if (pathName === "expense") {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        possibleValues: [...vinValues],
      },
      {
        value: "odo",
        label: t("odometer"),
        possibleValues: [...odometerValues],
      },
      {
        value: "expense_date",
        label: t("date_tv"),
        possibleValues: [
          "expense_date",
          "date",
          "dates",

          "expense date",
          "timestamp",
        ],
        type: "date",
      },
      {
        value: "expense_by",
        label: t("expense_by"),
        possibleValues: [
          "expense_by",
          "expense by",
          "user",

          //"Expense By",
          //"Expense by",
          //"expense by",
        ],
      },
      {
        value: "vendor",
        label: t("vendor"),
        possibleValues: ["vendor", "Vendor"],
      },
      {
        value: "expense_task_name",
        label: t("expense_task_name"),
        possibleValues: [...expenseTaskValues],
        isMultiple: true,
      },
      {
        value: "labor_cost",
        label: t("labor_cost"),
        possibleValues: ["labor_cost", "labor", "labor cost"],
      },
      {
        value: "parts_cost",
        label: t("parts_cost"),
        possibleValues: ["parts_cost", "parts", "parts cost"],
      },
      {
        value: "total_cost",
        label: t("tc_tv"),
        possibleValues: ["total_cost", "total", "total cost"],
      },
      {
        value: "tax",
        label: t("tax"),
        possibleValues: ["Tax", "tax"],
      },
      {
        value: "discount",
        label: t("discount"),
        possibleValues: ["Discount", "discount"],
      },
      {
        value: "transaction_type",
        label: t("transaction_type"),
        possibleValues: ["transaction_type", "transaction type"],
      },
      // {
      //   value: "card_number",
      //   label: t("card_number"),
      //   possibleValues: ["card_number", "card number"],
      // },
      {
        value: "comments",
        label: t("notes_tv"),
        //checked: false,
        possibleValues: [...commentsValues],
      },
    ];

    otherMandatoryFields = [
      { value: "expense_date", label: t("date_tv") },
      { value: "expense_task_name", label: t("expense_task_name") },
      { value: "odo", label: t("odometer") },
    ];

    queryString = "import_expense";
    screenHeading = t("expenses");
    sampleFileName = "expense_log_sample";
  }

  if (pathName === "inspectionforms") {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        possibleValues: [],
      },
      {
        value: "form_name",
        label: t("inspection_form_name"),
        //checked: false,
        possibleValues: [
          "form name",
          "name",
          "form",
          "inspection_form_name",
          "form_name",
          "inspection form name",

          //"Form name",
          //"form name",
          //"Inspection Form Name",
        ],
      },
      {
        value: "task_name",
        label: t("inspection_task"),
        //checked: false,
        possibleValues: [
          "task name",
          "task_name",
          "task",
          "inspection_task_name",
          "inspection task",

          //"Task Name",
          //"Task",
          //"Task name",
        ],
        isMultiple: true,
      },
      {
        value: "description",
        label: t("desc"),
        //checked: false,
        possibleValues: [
          "desc",
          "description",
          "description",
          //"Descriptions",
          //"Desc",
        ],
      },
      {
        value: "sign_required",
        label: t("sign_reqd"),
        //checked: false,
        possibleValues: [
          "sign_required",
          "sign required",
          "signature required",
          "signature reqd",
          "sign",

          //"Signature Required",
          //"Sign",
        ],
      },
      {
        value: "odo_required",
        label: t("odo_reqd"),
        //checked: false,
        possibleValues: [
          "odo_required",
          "odo required",
          "odo reqd",
          "odo",

          //"Odo Required",
          //"Odometer Required",
          //"Odo",
          //"odo_required",
        ],
      },
    ];

    otherMandatoryFields = [
      { value: "form_name", label: t("inspection_form_name") },
      { value: "task_name", label: t("inspection_task") },
    ];

    queryString = "import_inspection_form";
    screenHeading = t("inspection_forms");
    sampleFileName = "inspection_form_sample";
  }

  if (pathName === "groups") {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        possibleValues: [],
      },
      {
        value: "group_name",
        label: t("group_name"),
        //checked: false,
        possibleValues: [
          "group",
          "group_name",
          "group name",

          //"Group Name",
          //"Group name",
          //"Group",
        ],
      },
      {
        value: "parent_group",
        label: t("parent_group"),
        //checked: false,
        possibleValues: [
          "parent_group",
          "parent group",
          //"Parent Group",
          //"Parent group",
        ],
      },
      {
        value: "description",
        label: t("desc"),
        //checked: false,
        possibleValues: ["descriptions", "description", "desc"],
      },
    ];

    otherMandatoryFields = [{ value: "group_name", label: t("group_name") }];

    queryString = "import_groups";
    screenHeading = t("groups");
    sampleFileName = "groups_sample";
  }

  if (pathName === "vehicleassignment") {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        possibleValues: [],
      },
      {
        value: "name",
        label: t("veh_name"),
        //checked: false,
        possibleValues: [...vehicleNameValues],
      },
      {
        value: "license_no",
        label: t("license"),
        //checked: false,
        possibleValues: [...licenseValues],
      },
      {
        value: "vin_no",
        label: t("vin"),
        //checked: false,
        possibleValues: [...vinValues],
      },
      {
        value: "user_name",
        label: t("user_name"),
        //checked: false,
        possibleValues: [
          "user_id",
          "user",
          "user_name",
          "user name",
          "user id",
          // "User",
          // "User Id",
          // "user id",
          // "User Name",
          // "user name",
          // "user_name",
        ],
      },
      {
        value: "email",
        label: t("email"),
        //checked: false,
        possibleValues: ["email", "email_id", "email id"],
      },
    ];

    otherMandatoryFields = [
      { value: "user_name", label: t("user_name") },
      { value: "email", label: t("email") },
    ];

    queryString = "import_vehicle_assignment";
    screenHeading = t("vehicle_assignment");
    sampleFileName = "vehicle_assignment_sample";
  }

  if (pathName === "parts") {
    options = [
      {
        value: "skip",
        label: t("not_mapped"),
        possibleValues: [],
      },
      {
        value: "part_name",
        label: t("part_name"),
        possibleValues: ["part name", "part", "name"],
      },
      {
        value: "part_number",
        label: t("part_no"),
        possibleValues: ["part number", "part no", "part no"],
      },
      {
        value: "barcode",
        label: t("upc_barcode"),
        possibleValues: ["barcode", "upc", "upc/barcode"],
      },
      {
        value: "notes",
        label: t("notes"),
        possibleValues: ["notes", "part notes", "comments", "note"],
      },
      {
        value: "vendor",
        label: t("vendor"),
        possibleValues: ["vendor"],
      },
      {
        value: "location",
        label: t("warehouse_loc"),

        possibleValues: ["location", "warehouse", "warehouse location"],
      },
      {
        value: "store_room",
        label: t("store_room"),
        possibleValues: ["store room"],
      },
      {
        value: "rack_number",
        label: t("rack_no"),
        possibleValues: ["rack no", "rack number", "rack #", "rack"],
      },
      {
        value: "available_quantity",
        label: t("available_qty"),
        possibleValues: [
          "qty",
          "quantity",
          "available qty",
          "available quantity",
        ],
      },
      {
        value: "purchase_cost_per_unit",
        label: t("cost_per_unit"),
        possibleValues: ["cost per unit", "cost", "cost/unit"],
      },
    ];

    otherMandatoryFields = [
      { value: "part_name", label: t("part_name") },
      // { value: "location", label: t("warehouse_location") },
      // { value: "available_quantity", label: t("available_qty") },
    ];

    queryString = "import_inventory_parts";
    screenHeading = t("parts");
    sampleFileName = "parts_sample";
  }

  return {
    options,
    otherMandatoryFields,
    queryString,
    screenHeading,
    sampleFileName,
  };
};
