import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  anchorColor,
  anchorColorHover,
  colorLightGray,
  taskBgColor,
} from "../../../../../utils/palette";
import { ItemContainer, ItemLabel, ItemValue } from ".";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ActionIconContainer } from "../../../TableElements";
import { LinkSpan } from "../../../form/FormElements";
import getUnixTime from "date-fns/getUnixTime";
import { getListingDisplayDate } from "../../../../helpers";
import styled from "styled-components";
import { post, postApi } from "../../../../../api/getUser";
import PartsFormPopup from "../../../../../components/Inventory/Parts/PartsFormPopup";
import Snackbar from "../../../Snackbar";
import EditQuantityPopup from "../../../../../components/Inventory/Parts/EditQuantityPopup";
import { CustomSpecsPopup } from "./VehiclesRightPanel";
import { DeletePopup } from "../DeletePopup";
import Tooltip from "../../../Tooltip";

const PartsRightPanel = ({ rowData, setSnackbarMsg }) => {
  const { t } = useTranslation("common");
  const { currency } = useSelector((state) => state.units);

  const [batches, setBatches] = useState([]);

  //To toggle parts form popup
  const [showPartsForm, setShowPartsForm] = useState(false);
  //To toggle edit quantity
  const [showEditQuantity, setShowEditQuantity] = useState(false);
  //Not required as mode is always going to be 'edit-batch'
  //const [mode, setMode] = useState('edit-batch');

  const [editBatchRowData, setEditBatchRowData] = useState(null);

  const [customFields, setCustomFields] = useState([]);
  const [showCustomFields, setShowCustomFields] = useState(false);

  const [deleteBatchData, setDeleteBatchData] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  useEffect(() => {
    if (rowData) {
      (async () => {
        let temp = [];
        const batchesApiJson = {
          querystring: "fetch_parts_by_location",
          part_id: rowData.part_id,
          location: rowData.warehouse_loc || "",
        };
        const batchesApiRes = await postApi(batchesApiJson, "inventory");
        if (batchesApiRes.success) {
          const { inventory_data } = batchesApiRes;
          if (inventory_data?.length > 0) {
            temp = inventory_data.map((row) => {
              let customFields = [];
              const { custom_fields } = row;
              //Custom Fields
              if (custom_fields) {
                const customFieldsArr = JSON.parse(custom_fields);
                customFields = customFieldsArr.map((f, i) => ({
                  title: f.key,
                  author: f.value,
                  id: Date.now() + i,
                }));
              }
              return { ...row, viewMore: false, customFields };
            });
          }
        }
        setBatches(temp);
      })();
    }
  }, [rowData]);

  const handleShowDetails = (batchId) => {
    setBatches((prev) => {
      let temp = prev.map((b) => {
        if (b.batch_id === batchId) {
          return { ...b, viewMore: !b.viewMore };
        } else {
          return b;
        }
      });
      return temp;
    });
  };

  const handleEditBatch = (batch) => {
    setEditBatchRowData({
      ...batch,
      part_name: rowData.part_name,
      part_number: rowData.part_number,
      barcode: rowData.barcode,
      part_notes: rowData.part_notes,
      part_img: rowData.part_img,
    });
    setShowPartsForm(true);
  };

  const handleDeleteBatch = (batchId) => {
    setDeleteBatchData({
      delete_ids: batchId,
      querystring: "multiple_delete",
      sub_query_string: "PARTS_BATCHES",
    });
    setShowDeletePopup(true);
  };

  const handleUpdateQty = (batch) => {
    setEditBatchRowData({
      ...batch,
      part_name: rowData.part_name,
      part_number: rowData.part_number,
      barcode: rowData.barcode,
      part_notes: rowData.part_notes,
      part_img: rowData.part_img,
    });
    setShowEditQuantity(true);
  };

  const handleShowCustomFields = (batch) => {
    setCustomFields(batch?.customFields);
    setShowCustomFields(true);
  };

  return (
    <>
      {showDeletePopup && (
        <DeletePopup
          showPopup={showDeletePopup}
          setShowPopup={setShowDeletePopup}
          deleteApiData={deleteBatchData}
          screen={"batches"}
        />
      )}
      {showCustomFields && (
        <CustomSpecsPopup
          showPopup={showCustomFields}
          setShowPopup={setShowCustomFields}
          customSpecs={customFields}
          heading={t("custom_fields")}
        />
      )}
      {showPartsForm && (
        <PartsFormPopup
          showPopup={showPartsForm}
          setShowPopup={setShowPartsForm}
          mode={"edit-batch"}
          rowData={editBatchRowData}
          setSnackbarMsg={setSnackbarMsg}
        />
      )}
      {showEditQuantity && (
        <EditQuantityPopup
          showPopup={showEditQuantity}
          setShowPopup={setShowEditQuantity}
          rowData={editBatchRowData}
          setSnackbarMsg={setSnackbarMsg}
        />
      )}
      {batches.length === 0 && (
        <Row className="mx-0 my-2">
          <Col>
            <h5>{t("no_batches_added")}</h5>
          </Col>
        </Row>
      )}
      {batches.length > 0 && (
        <Row className="mx-0 my-2">
          <Col>
            <h5>{t("batches")}</h5>
          </Col>
        </Row>
      )}
      {batches.map((batch) => (
        <Row
          key={batch?.parts_batch_id}
          className="mx-1 my-2"
          style={{
            background: taskBgColor,
            //padding: "4px",
            //margin: "0.25rem 0",
            borderRadius: "5px",
          }}
        >
          <Col>
            <Row className="mt-1">
              <Col>
                <div className="d-flex justify-content-end gap-2">
                  <Tooltip text={t("edit_batch")}>
                    <ActionIconContainer onClick={() => handleEditBatch(batch)}>
                      <i className="fi fi-rr-edit" />
                    </ActionIconContainer>
                  </Tooltip>
                  <Tooltip text={t("delete_batch")}>
                    <ActionIconContainer
                      onClick={() => handleDeleteBatch(batch.parts_batch_id)}
                    >
                      <i className="fi fi-rr-trash" />
                    </ActionIconContainer>
                  </Tooltip>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <ItemContainer>
                  <ItemLabel>{t("batch_id")}</ItemLabel>
                  <ItemValue>{batch.batch_id}</ItemValue>
                </ItemContainer>
              </Col>
              <Col>
                <ItemContainer>
                  <ItemLabel>{t("location")}</ItemLabel>
                  <ItemValue>{batch.location}</ItemValue>
                </ItemContainer>
              </Col>
            </Row>

            <Row>
              <Col>
                <ItemContainer>
                  <ItemLabel>{t("available_qty")}</ItemLabel>
                  <ItemValue>
                    <div className="d-flex align-items-center gap-1">
                      <LinkSpan onClick={() => handleUpdateQty(batch)}>
                        {batch.available_quantity}
                      </LinkSpan>
                      <EditIconContainer
                        style={{ width: 18, height: 18 }}
                        onClick={() => handleUpdateQty(batch)}
                      >
                        <i
                          className="fi fi-rr-edit"
                          //   style={{
                          //     fontSize: 12,
                          //     width: 12,
                          //     height: 12,
                          //     top: 2,
                          //   }}
                        />
                      </EditIconContainer>
                    </div>
                  </ItemValue>
                </ItemContainer>
              </Col>
              <Col>
                <ItemContainer>
                  <ItemLabel>{t("cost_per_unit")}</ItemLabel>
                  <ItemValue>{`${batch.purchase_cost_per_unit} ${currency}`}</ItemValue>
                </ItemContainer>
              </Col>
            </Row>

            {batch.viewMore && (
              <>
                <Row>
                  <Col>
                    <ItemContainer>
                      <ItemLabel>{t("purchase_qty")}</ItemLabel>
                      <ItemValue>{batch.purchase_quantity}</ItemValue>
                    </ItemContainer>
                  </Col>
                  <Col>
                    <ItemContainer>
                      <ItemLabel>{t("purchase_cost")}</ItemLabel>
                      <ItemValue>{`${batch.purchase_total_cost} ${currency}`}</ItemValue>
                    </ItemContainer>
                  </Col>
                </Row>
                <Row>
                  {batch.stock_room && (
                    <Col>
                      <ItemContainer>
                        <ItemLabel>{t("stock_room")}</ItemLabel>
                        <ItemValue>{batch.stock_room}</ItemValue>
                      </ItemContainer>
                    </Col>
                  )}
                  {batch.rack_number && (
                    <Col>
                      <ItemContainer>
                        <ItemLabel>{t("rack_no")}</ItemLabel>
                        <ItemValue>{batch.rack_number}</ItemValue>
                      </ItemContainer>
                    </Col>
                  )}
                </Row>
                <Row>
                  {batch.vendor && (
                    <Col>
                      <ItemContainer>
                        <ItemLabel>{t("vendor")}</ItemLabel>
                        <ItemValue>{batch.vendor}</ItemValue>
                      </ItemContainer>
                    </Col>
                  )}
                  <Col>
                    <ItemContainer>
                      <ItemLabel>{t("purchase_date")}</ItemLabel>
                      <ItemValue>
                        {getListingDisplayDate(batch.purchase_date)}
                      </ItemValue>
                    </ItemContainer>
                  </Col>
                </Row>

                {batch?.customFields?.length > 0 && (
                  <Row>
                    <Col sm={12}>
                      <ItemContainer>
                        <ItemLabel>{t("custom_fields")}</ItemLabel>
                        <LinkSpan onClick={() => handleShowCustomFields(batch)}>
                          {t("count_fields_added", {
                            count: batch?.customFields?.length,
                          })}
                        </LinkSpan>
                      </ItemContainer>
                    </Col>
                  </Row>
                )}
              </>
            )}

            <Row>
              <Col>
                <ItemContainer>
                  <ItemValue>
                    <LinkSpan onClick={() => handleShowDetails(batch.batch_id)}>
                      {batch.viewMore ? t("hide_details") : t("show_details")}
                    </LinkSpan>
                  </ItemValue>
                </ItemContainer>
              </Col>
              {/* <Col>
                <ItemContainer>
                  <ItemValue>
                    <LinkSpan onClick={() => handleEditBatch(batch)}>
                      {t("edit_batch_details")}
                    </LinkSpan>
                  </ItemValue>
                </ItemContainer>
              </Col> */}
            </Row>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default PartsRightPanel;

//styles

const EditIconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  i {
    margin: 0;
    position: absolute !important;
    width: 12px;
    height: 12px;
    font-size: 12px;
    top: 2px;
    color: ${anchorColor};
  }

  &:hover {
    background: ${colorLightGray};
  }
`;
