import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Pagination, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  ActionIconContainer,
  AddFormButton,
  RowOptionsButtonContainer,
} from "../../../shared/components/TableElements";
import {
  CheckBoxInput,
  FormItem,
} from "../../../shared/components/form/FormElements";
import { formInputStyles } from "../../../shared/constants/styles";
import styled from "styled-components";
import {
  colorBorder,
  colorGray,
  colorLightGray,
  colorRowHover,
  scrollbarStyles,
} from "../../../utils/palette";
import { RowOptionsMenuWrap } from "../../../shared/components/table/components/RowOptions";
import { OptionsMenu } from "../../../shared/components/table/components/MoreOptions/MoreOptions";
import useClickAway from "../../../shared/custom-hooks/useClickAway";
import { post, postApi } from "../../../api/getUser";
import PartsFormPopup from "../Parts/PartsFormPopup";
import { TableSpinner } from "../../../shared/components/table/components/ReactTableConstructor";
import { useAuth } from "../../../contexts/AuthContext";
import Error from "@/shared/components/form/Error";
import { useSelector } from "react-redux";
import {
  PaginationInfo,
  PaginationWrap,
} from "../../../shared/components/table/components/ReactTablePagination";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronDoubleRightIcon from "mdi-react/ChevronDoubleRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronDoubleLeftIcon from "mdi-react/ChevronDoubleLeftIcon";
import useDebounce from "../../../shared/custom-hooks/useDebounce";
import { borderRadius, position } from "polished";
import SubscribePopup from "../../../shared/components/SubscribePopup";
import { NATEXT } from "../../../shared/constants";

const AddPartsPopup = ({
  showPopup,
  setShowPopup,
  serviceTaskDetailId,
  serviceTasks,
  setServiceTasks,
  partBatches,
  setPartBatches,
  setShowUnitsConsumedPopup,
  serviceTasksRef,
  //setShowPartsForm,
  setPartRowData,
  fromUnitsConsumed,
  setFromUnitsConsumed,
}) => {
  const { t } = useTranslation("common");
  const { userId } = useSelector((state) => state.user);
  const { reload, setReload, tag } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(false);

  //Pagination
  const pageSize = 10;
  const [pageIndex, setPageIndex] = useState(0);
  const [pageIndexArr, setPageIndexArr] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sorting, setSorting] = useState("desc");
  const [filterData, setFilterData] = useState({
    index: 0,
    sorting: "desc",
    search: "",
  });

  const batches = [
    {
      parts_batch_id: 1,
      part_name: "Castrol Radicool",
      part_id: 1,
      batch_id: "SF12",
      location: "Waterworks",
      available_quantity: 150,
      purchase_cost_per_unit: 34.5,
    },
    {
      parts_batch_id: 2,
      part_name: "Castrol Radicool",
      part_id: 1,
      batch_id: "SF13",
      location: "Mangalore",
      available_quantity: 200,
      purchase_cost_per_unit: 30,
    },
    {
      parts_batch_id: 3,
      part_name: "Engine Oil",
      batch_id: "SF15",
      part_id: 2,
      location: "Mangalore",
      available_quantity: 400,
      purchase_cost_per_unit: 40,
    },
  ];

  const [allBatches, setAllBatches] = useState([]);

  const [filterText, setFilterText] = useState("");
  const debounceFilterText = useDebounce(filterText, 500);

  const [showCreateNewPart, setShowCreateNewPart] = useState(false);

  const [selectedBatchId, setSelectedBatchId] = useState("");

  const [showPartsForm, setShowPartsForm] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState(false);
  const [batchRowData, setBatchRowData] = useState(false);
  const [mode, setMode] = useState("add-batch");

  const [selectedBatches, setSelectedBatches] = useState([]);
  const selectedBatchIds = selectedBatches?.map((b) => b?.parts_batch_id);

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [subscribePopupErrMsg, setsubscribePopupErrMsg] = useState("");

  const fetchPartsData = async (index = 0) => {
    setIsLoading(true);
    let skipBatches = [];
    // const existingBatches = serviceTasksRef.current.find(
    //   (t) => t.service_task_detail_id === serviceTaskDetailId.current
    // ).batches;
    let existingBatches = [];
    if (partBatches) {
      existingBatches = partBatches[serviceTaskDetailId.current] || [];
    }
    if (existingBatches?.length > 0) {
      skipBatches = existingBatches.map((b) => b.parts_batch_id);
    }
    const partsApiJson = {
      search: debounceFilterText || "",
      order: sorting,
      querystring: "get_all_parts_batches_data",
      page: index + 1,
      limit: pageSize,
      batch_ids_skip: skipBatches,
    };
    const partsApiRes = await postApi(partsApiJson, "listing");
    let temp = [];
    if (partsApiRes?.success) {
      const { parts_batch_data, total_records } = partsApiRes;
      // const existingBatches = serviceTasksRef.current.find(
      //   (t) => t.service_task_detail_id === serviceTaskDetailId.current
      // ).batches;
      // const existingBatchIds = existingBatches.map((b) => b.parts_batch_id);
      // const existingBatchesFiltered = parts_batch_data.filter(
      //   (b) => !existingBatchIds.includes(b.parts_batch_id)
      // );
      setTotalRecords(total_records);
      const noOfPages = Math.ceil(total_records / pageSize);
      setPageIndexArr([...Array(noOfPages).keys()]);
      setAllBatches(parts_batch_data);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     await fetchPartsData();
  //   })();
  // }, [filterData]);

  useEffect(() => {
    (async () => {
      await fetchPartsData();
    })();
  }, [debounceFilterText, sorting]);

  //Reload Effect
  //using reload effect in a non listing screen - need to check if any issues
  useEffect(() => {
    if (reload === "parts") {
      (async () => {
        setReload("");
        await fetchPartsData();
      })();
    }
  }, [reload]);

  //Effect to toggle create new part
  useEffect(() => {
    if (!debounceFilterText) {
      setShowCreateNewPart(false);
    }
    // if (debounceFilterText && totalRecords === 0) {
    //   setShowCreateNewPart(true);
    // }
    if (debounceFilterText) {
      // setFilterData((prev) => ({
      //   ...prev,
      //   index: 0,
      //   search: debounceFilterText,
      // }));

      const partNamesArr = allBatches.map((p) =>
        p.part_name?.toLocaleLowerCase()
      );

      const partNumbersArr = allBatches.map((p) =>
        p.part_number?.toLocaleLowerCase()
      );
      if (
        !partNamesArr.includes(debounceFilterText.toLocaleLowerCase()) &&
        !partNumbersArr.includes(debounceFilterText.toLocaleLowerCase())
      ) {
        setShowCreateNewPart(true);
      } else {
        setShowCreateNewPart(false);
      }
    }
  }, [debounceFilterText, allBatches]);

  // useEffect(() => {
  //   setPartBatches((prev) => {
  //     let temp = { ...prev };
  //     const newBatches = selectedBatches.map((b) => ({
  //       ...b,
  //       //costPerUnit: "",
  //       totalCost: "",
  //       unitsConsumed: "",
  //       action: 1,
  //     }));
  //     temp[serviceTaskDetailId.current] = newBatches;
  //     return temp;
  //   });
  //   // const newBatches = selectedBatches.map((b) => ({
  //   //   ...b,
  //   //   //costPerUnit: "",
  //   //   totalCost: "",
  //   //   unitsConsumed: "",
  //   //   action: 1,
  //   // }));
  //   // console.log("newBatches", newBatches);

  //   // const selectedServiceTask = serviceTasksRef.current.find(
  //   //   (t) => t.service_task_detail_id === serviceTaskDetailId.current
  //   // );
  //   // selectedServiceTask.batches = [...batches, ...serviceTaskRefNewBatches];

  //   // serviceTasksRef.current.forEach((task) => {
  //   //   if (task.service_task_detail_id === serviceTaskDetailId.current) {
  //   //     const existingBatches = task?.batches ? task.batches : [];
  //   //     task.batches.push(...newBatches);
  //   //   }
  //   // });
  // }, [selectedBatches]);

  const RowOptions = ({
    batch,
    selectedBatchId,
    setSelectedBatchId,
    setBatchRowData,
    setMode,
    //setPartRowData,
    setShowPartsForm,
    //setShowPopup,
  }) => {
    const [toggleOptions, setToggleOptions] = useState(false);

    //const [showPartsForm, setShowPartsForm] = useState(false);
    //const [snackbarMsg, setSnackbarMsg] = useState(false);
    const clickAwayRef = useClickAway({
      handleCloseElement: () => setToggleOptions(false),
    });
    // const [selectedBatchId, setSelectedBatchId] = useState("");

    const handleRowOptionsClick = (e) => {
      e.preventDefault();
      //setSelectedBatchId(batch?.parts_batch_id);
      setToggleOptions(!toggleOptions);
    };

    const onAddBatchClick = () => {
      setBatchRowData(batch);
      setMode("add-batch");
      //setToggleOptions(!toggleOptions);
      //setPartRowData(batch);
      setShowPartsForm(true);
      //setShowPopup(false);
    };

    return (
      <>
        {/* {showPartsForm && (
          <PartsFormPopup
            showPopup={showPartsForm}
            setShowPopup={setShowPartsForm}
            mode={"add-batch"}
            rowData={batch}
            setSnackbarMsg={setSnackbarMsg}
            //setShowSnackbar={setShowSnackbar}
          />
        )} */}
        <div
          className="d-flex align-items-start justify-content-center position-relative"
          ref={clickAwayRef}
        >
          <RowOptionsButtonContainer
            id="row-options-button"
            style={{ marginTop: 0 }}
            variant="outline-secondary"
            size="sm"
            onClick={(e) => handleRowOptionsClick(e)}
          >
            <i className="fi fi-rr-menu-dots-vertical"></i>
          </RowOptionsButtonContainer>
          {toggleOptions && (
            <RowOptionsMenu
              batch={batch}
              setSelectedBatchId={setSelectedBatchId}
              onAddBatchClick={onAddBatchClick}
            />
          )}
        </div>
      </>
    );
  };

  const RowOptionsMenu = ({ onAddBatchClick }) => {
    const handleAddBatch = () => {
      onAddBatchClick();
    };
    return (
      <RowOptionsMenuWrap
        id="row-options"
        style={{ position: "absolute", right: "0.4rem" }}
      >
        <OptionsMenu id="add-batch" onClick={handleAddBatch}>
          {t("add_batch")}
        </OptionsMenu>
      </RowOptionsMenuWrap>
    );
  };

  // const CreateNewPart = ({ partName }) => {
  //   return (
  //     <NewPartContainer>
  //       <p>{`Create a new part ${partName}`}</p>
  //     </NewPartContainer>
  //   );
  // };

  const handleFilterTextChange = (e) => {
    const { value } = e.target;
    setFilterText(value);
    setPageIndex(0);
    // setFilterData((prev) => ({ ...prev, index: 0, search: value }));

    // const partNamesArr = batches.map((p) => p.part_name?.toLocaleLowerCase());

    // if (!value) {
    //   setShowCreateNewPart(false);
    // }
    // if (value) {
    //   if (!partNamesArr.includes(value?.toLocaleLowerCase())) {
    //     setShowCreateNewPart(true);
    //   } else {
    //     setShowCreateNewPart(false);
    //   }
    // }
  };

  const handleBatchClick = (e, batch) => {
    // const serviceTaskBatchIds = selectedServiceTask.current?.batches?.map(
    //   (b) => b?.parts_batch_id
    // );
    // setServiceTasks((prev) => {
    //   let temp = [...prev];
    //   temp = temp.map((task) => {
    //     if (
    //       task.service_task_id === selectedServiceTask.current.service_task_id
    //     ) {
    //       let batches = task?.batches;
    //       if (serviceTaskBatchIds.includes(batch.parts_batch_id)) {
    //         batches = batches.filter(
    //           (b) => b.parts_batch_id !== batch.parts_batch_id
    //         );
    //       } else {
    //         batches.push(batch);
    //       }
    //       return { ...task, batches };
    //     }
    //     return task;
    //   });
    //   return temp;
    // });
    // if (e.target.tagName !== "SPAN" && e.target.tagName !== "I") {
    const { parts_batch_id } = batch;
    const { service_task_detail_id } = serviceTaskDetailId.current;
    //console.log("service_task_detail_id", service_task_detail_id);
    e.stopPropagation();

    // const item = selectedBatches.find(
    //   (b) => b.parts_batch_id === batch.parts_batch_id
    // );
    // console.log("item", item);
    // if (item) {
    //   setSelectedBatches((prev) =>
    //     prev.filter((b) => b.parts_batch_id !== item.parts_batch_id)
    //   );
    //   //Update serviceTasksRef when batch is removed
    //   // serviceTasksRef.current.forEach((task) => {
    //   //   if (task.service_task_detail_id === serviceTaskDetailId.current) {
    //   //     task.batches = task.batches.filter(
    //   //       (b) => b.parts_batch_id === item.parts_batch_id
    //   //     );
    //   //   }
    //   // });
    // } else {
    //   if (selectedBatches.length === 0) {
    //     setSelectedBatches([batch]);
    //   } else {
    //     setSelectedBatches((prev) => {
    //       let temp = [...prev];
    //       temp.push(batch);
    //       return temp;
    //     });
    //   }
    //   //Update serviceTasksRef when new batch is added
    //   // serviceTasksRef.current.forEach((task) => {
    //   //   if (task.service_task_detail_id === serviceTaskDetailId.current) {
    //   //     task.batches.push({
    //   //       ...batch,
    //   //       action: 1,
    //   //       costPerUnit: "",
    //   //       totalCost: "",
    //   //       unitsConsumed: "",
    //   //     });
    //   //   }
    //   // });
    // }

    if (
      e.target.tagName !== "SPAN" &&
      e.target.tagName !== "I" &&
      e.target.tagName !== "BUTTON"
    ) {
      setSelectedBatches((prev) => {
        if (prev.length === 0) {
          return [batch];
        } else {
          let temp = [...prev];
          const item = temp.find(
            (b) => b.parts_batch_id === batch.parts_batch_id
          );
          //console.log("item", item);
          if (item) {
            temp = temp.filter((b) => b.parts_batch_id !== item.parts_batch_id);
          } else {
            temp.push(batch);
          }
          return temp;
        }
      });
      setErrMsg("");
    }

    // setPartBatches((prev) => {
    //   let temp = { ...prev };
    //   if (temp.hasOwnProperty(service_task_detail_id)) {
    //     let existingBatches = temp[service_task_detail_id];
    //     if (existingBatches?.length === 0) {
    //       existingBatches.push(batch);
    //       return;
    //     }
    //     existingBatches = existingBatches.map((b) => {
    //       if (b.parts_batch_id === parts_batch_id) {
    //         return;
    //       } else {
    //         return b;
    //       }
    //     });
    //     temp[service_task_detail_id] = existingBatches;
    //   } else {
    //     temp[service_task_detail_id] = [batch];
    //   }

    //   return temp;
    // });
  };

  const handleClose = () => {
    if (fromUnitsConsumed) {
      setShowUnitsConsumedPopup(true);
      setFromUnitsConsumed(false);
    }
    setShowPopup(false);
  };

  const handleCreateNewPart = async () => {
    if (tag === "free-user") {
      const noOfPartsRes = await postApi(
        { querystring: "get_parts_count" },
        "inventory"
      );
      if (noOfPartsRes?.success && noOfPartsRes?.inventory_data >= 3) {
        setsubscribePopupErrMsg(t("no_of_parts_limit_err"));
        setShowSubscribePopup(true);
        return;
      }
    }

    if (tag === "essential-user") {
      setsubscribePopupErrMsg(t("subscribe_to_advanced_msg"));
      setShowSubscribePopup(true);
      return;
    }
    setBatchRowData({ part_name: debounceFilterText });
    setMode("add-part");
    setShowPartsForm(true);
  };

  const handleSubmit = () => {
    if (selectedBatches.length === 0) {
      setErrMsg(t("one_batch_reqd_err"));
      return;
    }

    //set fromUnitsConsumed to false since we want to reset this state
    if (fromUnitsConsumed) {
      setFromUnitsConsumed(false);
    }

    //Populate serviceTasksRef on submit
    // serviceTasksRef.current.forEach((task) => {
    //   if (task.service_task_detail_id === serviceTaskDetailId.current) {
    //     const newBatches = selectedBatches.map((b, i) => ({
    //       ...b,
    //       service_task_detail_id: serviceTaskDetailId.current,
    //       pc_id: `${userId}_${Date.now() + i}`,
    //       //costPerUnit: "",
    //       availableQuantity: b.available_quantity,
    //       currentlyAvailableQuantity: b.available_quantity, //Needed for calc in edit
    //       totalCost: "",
    //       unitsConsumed: "",
    //       action: 1,
    //     }));
    //     task.batches.push(...newBatches);
    //   }
    // });

    const newBatches = selectedBatches.map((b, i) => ({
      ...b,
      service_task_detail_id: serviceTaskDetailId.current,
      pc_id: `${userId}_${Date.now() + i}`,
      //costPerUnit: "",
      availableQuantity: b.available_quantity,
      currentlyAvailableQuantity: b.available_quantity, //Needed for calc in edit
      totalCost: "",
      unitsConsumed: "",
      action: 1,
    }));
    setPartBatches((prev) => {
      let temp = { ...prev };
      const existingBatches = temp[serviceTaskDetailId.current] || [];
      temp[serviceTaskDetailId.current] = [...existingBatches, ...newBatches];
      return temp;
    });

    setShowUnitsConsumedPopup(true);
    setShowPopup(false);
  };

  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={subscribePopupErrMsg}
        />
      )}
      {showPartsForm && (
        <PartsFormPopup
          showPopup={showPartsForm}
          setShowPopup={setShowPartsForm}
          mode={mode}
          rowData={batchRowData}
          setSnackbarMsg={setSnackbarMsg}
          //setShowSnackbar={setShowSnackbar}
        />
      )}
      <Modal show={showPopup} size="xl">
        <Modal.Header>
          <h4>{t("parts")}</h4>
          <div className="d-flex justify-content-end">
            <ActionIconContainer onClick={handleClose}>
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </div>
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <Container className="mt-4 mb-3">
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={8}>
                <FormItem>
                  <input
                    type="text"
                    value={filterText}
                    onChange={handleFilterTextChange}
                    style={formInputStyles}
                    placeholder={t("select_or_create_parts")}
                    onKeyDown={(e) =>
                      e.key === "Enter" &&
                      debounceFilterText &&
                      showCreateNewPart
                        ? handleCreateNewPart()
                        : null
                    }
                  />
                </FormItem>
              </Col>
            </Row>
            {showCreateNewPart && (
              <Row className="d-flex justify-content-center align-items-center">
                <Col md={8}>
                  <NewPartContainer onClick={handleCreateNewPart}>
                    <Trans
                      i18nKey="create_new_part"
                      t={t}
                      values={{
                        partName: debounceFilterText,
                      }}
                    />
                  </NewPartContainer>
                </Col>
              </Row>
            )}
          </Container>
          <Container className="m-0 p-0">
            <Row>
              <Col md={3}>
                <div className="mx-3 d-flex gap-1 align-items-center">
                  <p style={{ fontSize: "12px", fontWeight: 300 }}>
                    {t("part_name")}
                  </p>
                  <i
                    className={`fi fi-rr-angle-small-${
                      sorting === "desc" ? "down" : "up"
                    }`}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      top: "2px",
                    }}
                    onClick={() => {
                      setSorting((prev) => (prev === "desc" ? "asc" : "desc"));
                      setPageIndex(0);
                    }}
                  />
                </div>
              </Col>
              <Col md={2}>
                <p style={{ fontSize: "12px", fontWeight: 300 }}>
                  {t("part_no")}
                </p>
              </Col>
              <Col md={2}>
                <p style={{ fontSize: "12px", fontWeight: 300 }}>
                  {t("batch_id")}
                </p>
              </Col>
              <Col md={2}>
                <p style={{ fontSize: "12px", fontWeight: 300 }}>
                  {t("warehouse")}
                </p>
              </Col>
              <Col md={1}>
                <p style={{ fontSize: "12px", fontWeight: 300 }}>
                  {t("qty_tv")}
                </p>
              </Col>
              <Col md={1}>
                <p style={{ fontSize: "12px", fontWeight: 300 }}>
                  {t("cost_per_unit")}
                </p>
              </Col>
              <Col md={1}></Col>
            </Row>
            <RowContainer $height={`${window.innerHeight - 380}px`}>
              {isLoading ? (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              ) : (
                <>
                  {allBatches.length === 0 && (
                    <Row
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "-webkit-fill-available" }}
                    >
                      <Col md={2}>
                        <p>{t("no_records")}</p>
                      </Col>
                    </Row>
                  )}
                  {allBatches.map((batch) => (
                    <PartRow
                      key={batch.parts_batch_id}
                      onClick={(e) => handleBatchClick(e, batch)}
                    >
                      <Col md={3}>
                        <div className="d-flex gap-1">
                          <CheckBoxInput
                            type="checkbox"
                            checked={selectedBatchIds?.includes(
                              batch?.parts_batch_id
                            )}
                            readOnly
                            //onChange={(e) => handleBatchClick(e, batch)}
                          />
                          <p>{batch.part_name}</p>
                        </div>
                      </Col>
                      <Col md={2}>
                        <p>{batch.part_number || NATEXT}</p>
                      </Col>
                      <Col md={2}>
                        <p>{batch.batch_id}</p>
                      </Col>
                      <Col md={2}>
                        <p style={{ marginLeft: "0.5rem" }}>{batch.location}</p>
                      </Col>
                      <Col md={1}>
                        <p style={{ marginLeft: "1rem" }}>
                          {batch.available_quantity}
                        </p>
                      </Col>
                      <Col md={1}>
                        <p style={{ marginLeft: "1.5rem" }}>
                          {batch.purchase_cost_per_unit}
                        </p>
                      </Col>
                      <Col md={1}>
                        <RowOptions
                          batch={batch}
                          selectedBatchId={selectedBatchId}
                          setSelectedBatchId={setSelectedBatchId}
                          setBatchRowData={setBatchRowData}
                          setShowPartsForm={setShowPartsForm}
                          setShowPopup={setShowPopup}
                          setMode={setMode}
                        />
                      </Col>
                    </PartRow>
                  ))}
                </>
              )}
            </RowContainer>
            {errMsg && (
              <Row className="mx-1 my-0">
                <Col>
                  <Error error={errMsg} />
                </Col>
              </Row>
            )}
            <PaginationWrap className="my-2 mx-2 p-0">
              <Pagination>
                <Pagination.First
                  disabled={pageIndex === 0}
                  className="pagination__item--arrow"
                  onClick={async () => {
                    setPageIndex(0);
                    await fetchPartsData(0);
                  }}
                >
                  <ChevronDoubleLeftIcon />
                </Pagination.First>
                <Pagination.Prev
                  disabled={pageIndex === 0}
                  className="pagination__item--arrow"
                  onClick={async () => {
                    let temp = pageIndex;
                    setPageIndex((prev) => prev - 1);
                    await fetchPartsData(temp - 1);
                  }}
                >
                  <ChevronLeftIcon />
                </Pagination.Prev>
                {pageIndexArr.map((i) => (
                  <Pagination.Item
                    id="page"
                    key={i}
                    active={pageIndex === i}
                    onClick={async () => {
                      setPageIndex(i);
                      await fetchPartsData(i);
                    }}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  disabled={pageIndex === pageIndexArr.length - 1}
                  className="pagination__item--arrow"
                  onClick={async () => {
                    let temp = pageIndex;
                    setPageIndex((prev) => prev + 1);
                    await fetchPartsData(temp + 1);
                  }}
                >
                  <ChevronRightIcon />
                </Pagination.Next>
                <Pagination.Last
                  disabled={pageIndex === pageIndexArr.length - 1}
                  className="pagination__item--arrow"
                  onClick={async () => {
                    setPageIndex(pageIndexArr.length - 1);
                    await fetchPartsData(pageIndexArr.length - 1);
                  }}
                >
                  <ChevronDoubleRightIcon />
                </Pagination.Last>
              </Pagination>
              <PaginationInfo>
                {/* Showing {pageSize * pageIndex + 1} to{" "}
        {pageSize * pageIndex + page.length} of {noOfRows} */}
                {t("showing_from_to_no_of_rows", {
                  from: totalRecords > 0 ? pageSize * pageIndex + 1 : 0,
                  to: Math.min(pageSize * (pageIndex + 1), totalRecords),
                  noOfRows: totalRecords,
                })}
              </PaginationInfo>
            </PaginationWrap>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <AddFormButton
            id="submit-form-button"
            type="button"
            onClick={handleSubmit}
            disabled={allBatches.length === 0}
          >
            {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
            {t("submit")}
          </AddFormButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPartsPopup;

//styles

const PartRow = styled(Row)`
  cursor: pointer;
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
  display: flex;
  align-items: center;
  //border-bottom: 1px solid ${colorBorder};

  &:hover {
    background: ${colorRowHover};
  }
`;

export const RowContainer = styled(Container)`
  overflow: auto;
  height: ${(p) => p.$height};
  margin: 0;
  ${scrollbarStyles}
`;

const NewPartContainer = styled.div`
  cursor: pointer;
  padding: 0.35rem 0.5rem;
  display: flex;
  align-items: center;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  background: ${colorRowHover};

  &:active {
    background: ${colorLightGray};
  }
`;
